import React from "react";

import { features } from "config/feature_flags";

import Feature from "components/feature";

import BookingComOnboarding from "./booking_com_onboarding";
import ChannelReadiness from "./channel_readiness";
import LiveFeedEventsListener from "./live_feed_events_listener";
import SignTermsAndConditionsAgreement from "./sign_terms_and_conditions_agreement";

export default function GlobalComponents() {
  return (
    <>
      <SignTermsAndConditionsAgreement />
      <BookingComOnboarding />
      <Feature name={features.LIVE_FEED_NOTIFICATIONS}>
        <LiveFeedEventsListener />
      </Feature>
      <ChannelReadiness />
    </>
  );
}
