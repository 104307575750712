import React, { Component } from "react";
import { Form, Space } from "antd";

import getFieldLayout from "../utils/get_field_layout";

const FormItem = Form.Item;

class InputGroup extends Component {
  render() {
    const {
      label,
      view,
      children,
      ...restProps
    } = this.props;

    const formItemLayout = getFieldLayout(view);

    return (
      <FormItem
        label={label}
        {...formItemLayout}
        {...restProps}
      >
        <Space.Compact block>
          {children}
        </Space.Compact>
      </FormItem>
    );
  }
}

export default InputGroup;
