import { daysBetween } from "utils/dates";
import dayjs from "utils/dayjs";

export class ChannelAvailabilityRules {
  constructor({ availabilityRules, dates, ratePlans }) {
    this._rules = availabilityRules;
    this._dates = dates;
    this._ratePlans = ratePlans;

    this._effectiveRules = [];
    this._rulesByRatePlanId = {};

    this._init();
  }

  getOverride({ ratePlanId, date, type }) {
    if (this._effectiveRules.length === 0) {
      return null;
    }

    const override = this._rulesByRatePlanId[ratePlanId]?.[date];

    if (type === undefined) {
      return override || null;
    }

    return override?.[type] || null;
  }

  _init() {
    if (!this._rules || this._rules.length === 0) {
      return;
    }

    const lastInventoryDate = dayjs(this._dates[this._dates.length - 1]);
    const firstInventoryDate = dayjs(this._dates[0]);

    this._effectiveRules = this._rules
      .filter((rule) => {
        const ruleStartDate = dayjs(rule.startDate);

        // rules start date should always be before or equal to last inventory date
        // otherwise, it means the rule is not effective yet, it starts from a future date
        if (!ruleStartDate.isSameOrBefore(lastInventoryDate)) {
          return false;
        }

        // if rule end date is null, it means the rule is effective
        if (!rule.endDate) {
          return true;
        }

        // if rule have end date, it should be after or equal to first inventory date
        if (dayjs(rule.endDate).isSameOrAfter(firstInventoryDate)) {
          return true;
        }

        return false;
      });

    if (this._effectiveRules.length === 0) {
      return;
    }

    this._calcRulesByRatePlanIds();
  }

  _calcRulesByRatePlanIds() {
    this._ratePlans.forEach((ratePlan) => {
      const matchedRules = this._effectiveRules.filter((rule) => this._isRuleMatchRatePlan(rule, ratePlan));

      if (matchedRules.length === 0) {
        return;
      }

      this._rulesByRatePlanId[ratePlan.id] = this._rulesByRatePlanId[ratePlan.id] || {};

      matchedRules.forEach((rule) => {
        const ruleStartDate = dayjs(rule.startDate);
        const inventoryStartDate = dayjs(this._dates[0]);
        const startDate = ruleStartDate.isAfter(inventoryStartDate) ? ruleStartDate : inventoryStartDate;

        const ruleEndDate = dayjs(rule.endDate);
        const inventoryEndDate = dayjs(this._dates[this._dates.length - 1]);
        const endDate = ruleEndDate.isBefore(inventoryEndDate) ? ruleEndDate : inventoryEndDate;

        daysBetween(startDate, endDate).forEach((date) => {
          this._rulesByRatePlanId[ratePlan.id][date] = this._rulesByRatePlanId[ratePlan.id][date] || {};
          this._rulesByRatePlanId[ratePlan.id][date][rule.type] = this._rulesByRatePlanId[ratePlan.id][date][rule.type] || [];

          this._rulesByRatePlanId[ratePlan.id][date][rule.type].push(rule);
        });
      });
    });
  }

  _isRuleMatchRatePlan(rule, ratePlan) {
    return rule.affectedRoomTypes.includes(ratePlan.room_type_id) && rule.affectedChannels.includes(ratePlan.channel_id);
  }
}
