import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";
import RateForm from "components/rate_form";

class RateViewDrawer extends Component {
  render() {
    const { t, ratePlanId, roomTypeId, isEditable, visible, onClose } = this.props;

    const title = ratePlanId
      ? t("rates_page:edit_dialog_header")
      : t("rates_page:create_dialog_header");

    return (
      <ChannexDrawer visible={visible} onClose={onClose} title={title}>
        {({ handleCloseFromContent, componentRef }) => (
          <DrawerFormWrapper>
            <RateForm
              id={ratePlanId}
              roomTypeId={roomTypeId}
              isEditable={isEditable}
              componentRef={componentRef}
              onClose={handleCloseFromContent}
            />
          </DrawerFormWrapper>
        )}
      </ChannexDrawer>
    );
  }
}

export default withTranslation()(RateViewDrawer);
