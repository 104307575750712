import React, { useState } from "react";
import { Uppy } from "@uppy/core";
import ImageEditor from "@uppy/image-editor";
import { DashboardModal } from "@uppy/react";
import XHR from "@uppy/xhr-upload";
import { Button } from "antd";
import store from "store";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/image-editor/dist/style.min.css";
import styles from "./photo_uploader.module.css";

const { transport: { settings: { secure, server } } } = store;

export const PhotoUploader = ({ label, button, multiple, onChange }) => {
  const [open, setOpen] = useState();

  const getAuthToken = () => {
    return store.storage.getState().session.authToken;
  };

  const [uppy] = useState(() => new Uppy({
    restrictions: {
      allowedFileTypes: ["image/*"],
      maxNumberOfFiles: multiple ? null : 1,
    },
  })
    .use(ImageEditor)
    .use(XHR, {
      endpoint: `${secure ? "https" : "http"}://${server}/api/v1/photos/upload`,
      fieldName: "photo",
      async onBeforeRequest(xhr) {
        xhr.setRequestHeader("authorization", `Bearer ${getAuthToken()}`);
      },
      async onAfterResponse(xhr) {
        if (xhr.status === 401) {
          await store.http._refreshTokens();
        }
      },
    })
    .on("complete", (result) => {
      const urls = result.successful.map(({ response }) => response.body.url);
      onChange(multiple ? urls : urls[0]);
    }));

  const handleOpenClick = () => setOpen(true);

  const renderDefaultButton = () => {
    return (
      <Button block className={styles.loadButton} size="large" type="primary">
        {label}
      </Button>
    );
  };

  const view = button || renderDefaultButton();
  const updatedButton = React.cloneElement(view, { onClick: handleOpenClick, loading: false });

  return (
    <>
      {updatedButton}

      <DashboardModal
        uppy={uppy}
        open={open}
        proudlyDisplayPoweredByUppy={false}
        waitForThumbnailsBeforeUpload
        showProgressDetails
        hidePauseResumeButton
        closeModalOnClickOutside
        closeAfterFinish
        singleFileFullScreen
        onRequestClose={() => {
          setOpen(false);
          uppy.clear();
        }}
      />
    </>
  );
};
