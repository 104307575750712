import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import LiveFeedEventView from "components/live_feed_event_view";

export default function LiveFeedEventDrawer({ eventId, visible, zIndex, onClose }) {
  const { t } = useTranslation();

  return (
    <ChannexDrawer
      title={t("live_feed_event_drawer:header", { id: eventId })}
      visible={visible}
      onClose={onClose}
      zIndex={zIndex}
    >
      {({ handleCloseFromContent }) => (
        <LiveFeedEventView id={eventId} onClose={handleCloseFromContent} />
      )}
    </ChannexDrawer>
  );
}
