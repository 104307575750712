import * as yup from "yup";

import errorMessages from "config/constants/errors";

export const validationSchema = yup.object().shape({
  title: yup.string().trim().required(errorMessages.required()),
  type: yup.string().trim().required(errorMessages.required()),
  value: yup.string()
    .nullable()
    .trim()
    .when("type", {
      is: (typeValue) => {
        return ["availability_offset", "max_availability"].includes(typeValue);
      },
      then: (schema) => schema.required(errorMessages.required()),
    }),
  affectedDates: yup
    .array()
    .test("ensure start date", errorMessages.required(), (value) => {
      const [startDate, _endDate] = value || [];

      return !!startDate;
    })
    .required(errorMessages.required()),
  affectedChannels: yup
    .array().of(
      yup.string().trim().required(errorMessages.required()),
    )
    .min(1, errorMessages.required())
    .required(errorMessages.required()),
  affectedRoomTypes: yup
    .array().of(
      yup.string().trim().required(errorMessages.required()),
    )
    .min(1, errorMessages.required())
    .required(errorMessages.required()),
});
