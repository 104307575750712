import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { getIsAppInstalled } from "store/storage/selectors/applications_selector";
import { getUser } from "store/storage/selectors/session_selector";

import appCodes from "config/constants/app_codes";
import { features } from "config/feature_flags";

import useFeatures from "hooks/use_features";

import styles from "./application_links.module.css";

export default function ApplicationLinks({ handleCloseMenu }) {
  const { t } = useTranslation();
  const isMessagesInstalled = useSelector(getIsAppInstalled({ applicationCode: appCodes.messages }));
  const isPCIInstalled = useSelector(getIsAppInstalled({ applicationCode: appCodes.pci }));
  const isMobile = useMediaQuery({ query: "(max-width: 1275px)" });

  const { isFeatureEnabled } = useFeatures();
  const navigate = useNavigate();
  const location = useLocation();

  const appLinks = [];

  if (isMessagesInstalled) {
    appLinks.push(["/messages", "messages"]);
    appLinks.push(["/reviews", "reviews"]);
  }

  if (isPCIInstalled) {
    appLinks.push(["/pci", "channex_pci"]);
  }

  if (isFeatureEnabled(features.TRIPLA_IMPORT)) {
    appLinks.push(["/tripla-import", "tripla_import"]);
  }

  // DELME
  const user = useSelector(getUser);
  if (isFeatureEnabled(features.APALEO_IMPORT)) {
    appLinks.push(["/apaleo-import", "apaleo_import"]);

    if (user.settings?.allow_apaleo_reserva_import) {
      appLinks.push(["/apaleo-reserva-import", "apaleo_import_reserva"]);
    }
  }

  const items = [{
    key: "applications",
    label: <div className={styles.applicationLink}>{t("general:links:applications")}</div>,
    children: [
      {
        key: "/applications",
        onClick: () => { handleCloseMenu(); navigate("/applications"); },
        label: (
          <div>
            {t("general:links:manage_applications")}
          </div>
        ),
      },
      {
        type: "divider",
      },
      ...appLinks.map(([url, code]) => (
        {
          key: url,
          onClick: () => { handleCloseMenu(); navigate(url); },
          label: (
            <div>
              {t(`general:links:${code}`)}
            </div>
          ),
        }
      )),
    ],
  }];

  let selectedKeys = [];
  if (["/applications", ...appLinks.map(([path]) => path)].includes(location.pathname)) {
    selectedKeys = [location.pathname];
  }

  return (
    <Menu selectedKeys={selectedKeys} items={items} rootClassName={styles.root} mode={isMobile ? "inline" : "horizontal"} />
  );
}
