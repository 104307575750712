import React from "react";
import PropTypes from "prop-types";

import GroupPerPersonAutoRate from "components/forms/groups/group_per_person_auto_rate";
import GroupPerPersonDerivedRate from "components/forms/groups/group_per_person_derived_rate";
import GroupPerPersonManualRate from "components/forms/groups/group_per_person_manual_rate";
import InputRate from "components/forms/items/input_rate";

function GroupAssignRateSettings(props) {
  const { model, rateCategory } = props;
  const code = [
    rateCategory.sell_mode,
    rateCategory.rate_mode,
    Boolean(rateCategory.inherit_rate),
  ].join(":");

  let result = null;

  switch (code) {
    case "per_room:manual:false":
      result = <InputRate {...props} />;
      break;
    case "per_room:derived:false":
      result = <InputRate {...props} />;
      break;
    case "per_person:derived:false":
      result = (
        <GroupPerPersonDerivedRate
          {...props}
          model={{ ...model, inherit_rate: false, is_derived: false }}
        />
      );
      break;
    case "per_person:derived:true":
      result = (
        <GroupPerPersonDerivedRate
          {...props}
          hideRateInput
          model={{ ...model, inherit_rate: true, is_derived: true }}
        />
      );
      break;
    case "per_person:manual:false":
      result = <GroupPerPersonManualRate {...props} />;
      break;
    case "per_person:auto:false":
      result = <GroupPerPersonAutoRate hideAutoOptions {...props} />;
      break;
    default:
      result = null;
      break;
  }

  return result;
}

GroupAssignRateSettings.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default GroupAssignRateSettings;
