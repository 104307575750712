import queryString from "query-string";

import appModes from "config/constants/app_modes";

import runtimeStorage from "utils/runtime_storage";

let storage = null;

class AppStorageManager {
  constructor() {
    const query = queryString.parse(window.location.search);
    const { app_mode: appMode = appModes.DEFAULT } = query;

    storage = appMode === appModes.HEADLESS ? runtimeStorage : localStorage;
  }

  setItem = (name, value) => {
    try {
      const valueToStash = JSON.stringify(value);

      storage.setItem(name, valueToStash);

      return true;
    } catch (_error) {
      return false;
    }
  };

  getItem = (name) => {
    try {
      const stashedValue = storage.getItem(name);

      return JSON.parse(stashedValue);
    } catch (_error) {
      return null;
    }
  };

  removeItem = (name) => {
    storage.removeItem(name);
  };

  clear = () => {
    storage.clear();
  };
}

export default new AppStorageManager();
