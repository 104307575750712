import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "store";
import { getActiveGroup, getActivePropertyId, getAppMode } from "store/storage/selectors/session_selector";

import APP_MODES from "config/constants/app_modes";

import Loading from "components/loading";

import { useTimezones } from "hooks/use_timezones/use_timezones";

import EventEmitter from "utils/event_emitter";

import useUserChannelSubscription from "./data_loader_hooks/use_user_channel_subscription";

const { Properties, Groups } = store;

export default function DataLoader({ children }) {
  const appMode = useSelector(getAppMode);
  const activeProperty = useSelector(getActivePropertyId);
  const activeGroup = useSelector(getActiveGroup);
  const timezones = useTimezones();

  useUserChannelSubscription();

  const [isInitialGroupOptionsLoaded, setIsInitialGroupOptionsLoaded] = useState(false);
  const [isInitialPropertyOptionsLoaded, setIsInitialPropertyOptionsLoaded] = useState(false);

  const getFilters = useCallback(() => {
    const filters = {};

    if (appMode === APP_MODES.HEADLESS) {
      if (activeProperty) {
        filters.id = activeProperty;
      }

      if (activeGroup) {
        filters.group_id = activeGroup;
      }
    }

    return filters;
  }, [appMode, activeProperty, activeGroup]);

  const loadPropertiesOptions = useCallback(async () => {
    const filter = getFilters();
    const options = await Properties.options(filter);

    Properties.setOptions(options);
    setIsInitialPropertyOptionsLoaded(true);
  }, [getFilters]);

  const loadGroupsOptions = useCallback(async () => {
    const filter = getFilters();
    const options = await Groups.options(filter);

    Groups.setOptions(options);
    setIsInitialGroupOptionsLoaded(true);
  }, [getFilters]);

  useEffect(() => { loadGroupsOptions(); }, [loadGroupsOptions]);
  useEffect(() => { loadPropertiesOptions(); }, [loadPropertiesOptions]);

  useEffect(() => {
    EventEmitter.bind("property:deleted", loadPropertiesOptions);
    EventEmitter.bind("property:created", loadPropertiesOptions);
    EventEmitter.bind("property:updated", loadPropertiesOptions);
    EventEmitter.bind("onboarding:confirmed", loadPropertiesOptions);
    EventEmitter.bind("group:updated", loadGroupsOptions);
    EventEmitter.bind("group:created", loadGroupsOptions);
    EventEmitter.bind("group:deleted", loadGroupsOptions);

    return () => {
      EventEmitter.unbind("property:deleted", loadPropertiesOptions);
      EventEmitter.unbind("property:created", loadPropertiesOptions);
      EventEmitter.unbind("property:updated", loadPropertiesOptions);
      EventEmitter.unbind("onboarding:confirmed", loadPropertiesOptions);
      EventEmitter.unbind("group:updated", loadGroupsOptions);
      EventEmitter.unbind("group:created", loadGroupsOptions);
      EventEmitter.unbind("group:deleted", loadGroupsOptions);
    };
  }, [loadPropertiesOptions, loadGroupsOptions]);

  if (!isInitialPropertyOptionsLoaded || !isInitialGroupOptionsLoaded || timezones.isLoading) {
    return <Loading />;
  }

  return children;
}
