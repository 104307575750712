import React from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import channelCodes from "config/constants/channels/channel_codes";

import SubmitButton from "components/forms/buttons/submit_button";
import GlobalErrors from "components/forms/global_errors";
import FormInput from "components/forms/inputs/hook_form/form_input";
import { FormListSelector } from "components/forms/inputs/hook_form/form_list_selector/form_list_selector";
import { FormRangePicker } from "components/forms/inputs/hook_form/form_rangepicker";
import FormSelect from "components/forms/inputs/hook_form/form_select";
import { TextValue } from "components/forms/inputs/text_value/text_value";
import BottomActions from "components/forms/layout/drawer/bottom_actions/bottom_actions";
import useAppForm from "components/hook_form/use_app_form";

import { validationSchema } from "./validation_schema";

const parseToForm = (value) => {
  if (!value) {
    return value;
  }

  return {
    ...value,
    affectedDates: [
      value.startDate ? dayjs(value.startDate) : null,
      value.endDate ? dayjs(value.endDate) : null,
    ],
  };
};

const parseToModel = (value) => {
  const { affectedDates, ...rest } = value;
  const [startDate, endDate] = affectedDates;

  return {
    ...rest,
    startDate: startDate?.format("YYYY-MM-DD"),
    endDate: endDate?.format("YYYY-MM-DD"),
  };
};

export function AvailabilityRuleForm({ value, channels, roomTypes, isLoading, onSubmit }) {
  const { t } = useTranslation();
  const parsedValue = parseToForm(value);

  const TYPES = {
    close_out: t("general:restrictions:close_out"),
    availability_offset: t("general:restrictions:availability_offset"),
    max_availability: t("general:restrictions:max_availability"),
  };

  const { handleSubmit, errors, control, watch } = useAppForm({
    defaultValue: parsedValue,
    validationSchema,
    fieldNames: ["title", "affectedDates", "type", "value", "affectedChannels", "affectedRoomTypes"],
    submitHandler: (formValue) => {
      onSubmit(parseToModel(formValue));
    },
  });

  const isEdit = !!value?.id;
  const type = watch("type");
  const isValuePresent = ["availability_offset", "max_availability"].includes(type);

  const channelOptions = channels.map((channel) => {
    const channelConfig = channelCodes[channel.channel] || channelCodes.Unknown;

    return {
      id: channel.id,
      label: `${channel.title} (${channelConfig.title})`,
    };
  });
  const roomTypeOptions = roomTypes.map((roomType) => ({ id: roomType.id, label: roomType.title }));

  return (
    <div>
      <div>
        <GlobalErrors hookForm errors={errors} />

        {isEdit && (
          <TextValue
            label="Id"
            value={value.id}
            copy
          />
        )}
        <FormInput
          name="title"
          label="Title"
          errors={errors?.title?.message}
          control={control}
        />
        <FormRangePicker
          disabledDate={(current) => current.isBefore(dayjs().subtract(1, "day"))}
          name="affectedDates"
          label="Affected Dates"
          allowEmpty={[false, true]}
          format="YYYY-MM-DD"
          control={control}
          errors={errors?.affectedDates?.message}
        />
        <FormSelect
          name="type"
          label="Type"
          placeholder="Select Type"
          control={control}
          errors={errors?.type?.message}
          options={Object.entries(TYPES).map(([optionKey, optionValue]) => ({ value: optionKey, label: optionValue }))}
        />
        {isValuePresent && (
          <FormInput
            name="value"
            label="Value"
            errors={errors?.value?.message}
            control={control}
          />
        )}
        <FormListSelector
          name="affectedChannels"
          label="Affected Channels"
          control={control}
          errors={errors?.affectedChannels?.message}
          items={channelOptions}
        />
        <FormListSelector
          name="affectedRoomTypes"
          label="Affected Room Types"
          control={control}
          errors={errors?.affectedRoomTypes?.message}
          items={roomTypeOptions}
        />
      </div>

      <BottomActions>
        <SubmitButton onClick={() => { handleSubmit(); }} loading={isLoading}>
          Save
        </SubmitButton>
      </BottomActions>
    </div>
  );
}
