import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Select } from "antd";

import getFieldLayout from "./utils/get_field_layout";
import getFieldStyle from "./utils/get_field_style";

import styles from "./inputs.module.css";

const Option = Select.Option;
const OptGroup = Select.OptGroup;
const FormItem = Form.Item;

const DEFAULT_FILTER = (input, option) => {
  const value = option.label || option.children;
  const formattedValue = String(value).toLowerCase();
  const formattedInput = input.toLowerCase();

  return formattedValue.includes(formattedInput);
};

class FormSelect extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    allowClear: PropTypes.bool,
  };

  static validation(errors) {
    if (errors) {
      const message = Array.isArray(errors) ? errors.join(" ") : errors;

      return {
        validateStatus: "error",
        help: <span data-cy="input_error_message">{message}</span>,
      };
    }
    return {};
  }

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      stateValue: null,
    };
  }

  componentDidMount() {
    const { defaultValue } = this.props;

    this.setState({
      stateValue: defaultValue,
    });
  }

  // react hook form requires this method to be defined
  focus = () => {};

  handleChange(value) {
    const { name, changeEvent, onChange } = this.props;
    this.setState({
      stateValue: value,
    });

    if (onChange && changeEvent) {
      onChange({
        target: {
          name,
          value,
        },
      });

      return;
    }

    if (onChange) {
      onChange(value, name);
    }
  }

  renderOptions() {
    const {
      name,
      options,
      isGroup = false,
      nativeOptions = false,
      valueKey = "value",
      labelKey = "representation",
      optionRenderer,
    } = this.props;

    if (nativeOptions) {
      return null;
    }

    if (optionRenderer) {
      return options.map((option) => optionRenderer(option));
    }

    if (isGroup) {
      const itemsByCategory = options.reduce((acc, item) => {
        const { category = "default" } = item;
        const { [category]: groupedItems = [] } = acc;
        groupedItems.push(item);
        return { ...acc, [category]: groupedItems };
      }, {});

      return Object.keys(itemsByCategory).map((groupKey) => {
        const groupOptions = itemsByCategory[groupKey].map((option) => (
          <Option
            key={option[valueKey]}
            value={option[valueKey]}
            disabled={option.disabled}
            data-cy={`select_${name}_option_${option[valueKey]}`}
          >
            {option[labelKey] || option.label}
          </Option>
        ));

        return (
          <OptGroup label={groupKey} key={groupKey}>
            {groupOptions}
          </OptGroup>
        );
      });
    }

    if (!options) {
      return [];
    }

    return options.map((option) => {
      return (
        <Option
          key={option[valueKey]}
          value={option[valueKey]}
          disabled={option.disabled}
          data-cy={`select_${name}_option_${option[valueKey]}`}
        >
          {option[labelKey] || option.label}
        </Option>
      );
    });
  }

  render() {
    const {
      name,
      placeholder,
      inputRef,
      errors,
      label,
      view,
      defaultValue,
      value,
      mode,
      allowClear,
      filterOption = DEFAULT_FILTER,
      disabled,
      loading,
      className = "",
      showSearch = true,
      uncontrolled = false,
      onChange,
      onBlur = () => {},
      extra,
      grouped = false,
      nativeOptions = false,
      options,
    } = this.props;
    const { stateValue } = this.state;
    const formItemLayout = grouped ? {} : getFieldLayout(view);
    const style = getFieldStyle(this.props);

    let additionalProps = {};

    if (!uncontrolled) {
      additionalProps = {
        value: onChange ? value : value || stateValue,
      };
    }

    return (
      <FormItem
        {...formItemLayout}
        {...FormSelect.validation(errors)}
        label={label}
        style={style}
        className={className}
        data-cy={`select_${name}`}
        extra={extra}
      >
        <Select
          className={styles.input}
          showSearch={showSearch}
          mode={mode}
          autoComplete="disabled"
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={this.handleChange}
          onBlur={onBlur}
          filterOption={filterOption}
          allowClear={allowClear || false}
          disabled={disabled || false}
          loading={loading || false}
          ref={inputRef}
          options={nativeOptions && options}
          {...additionalProps}
        >
          {this.renderOptions()}
        </Select>
        <input
          style={{ display: "none" }}
          defaultValue={onChange ? value : value || stateValue}
        />
      </FormItem>
    );
  }
}

export default FormSelect;
