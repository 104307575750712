import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Select } from "antd";
import _ from "lodash";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import ChannexModal from "components/channex_modal";
import InventoryRatePlan from "components/inventory/inventory_rate_plan";
import InventoryValueOverrideController from "components/inventory/inventory_value_override_controller";
import InventoryValueSwitchController from "components/inventory/inventory_value_switch_controller";
import RangePicker from "components/mobile/inventory/range_picker";

import getArrayDays from "utils/get_array_days";
import sortRestrictionsByRepresentation from "utils/translated_sort";
import validateRestriction from "utils/validate_restriction";

const VISIBLE_RESTRICTIONS = [
  "rate",
  "stop_sell_manual",
  "closed_to_arrival",
  "closed_to_departure",
  "min_stay_arrival",
  "min_stay_through",
  "max_stay",
  "availability",
];

const booleanRestrictions = [
  "stop_sell",
  "stop_sell_manual",
  "closed_to_arrival",
  "closed_to_departure",
];
const AVAILABILITY_RESTRICTION = "availability";
const BOOKED_RESTRICTION = "booked";

class InventoryValueOverrideModal extends Component {
  static propTypes = {
    t: PropTypes.func,
    isVisible: PropTypes.bool,
    restriction: PropTypes.string,
    date: PropTypes.array,
    values: PropTypes.any,
    handleOk: PropTypes.func,
    handleCancel: PropTypes.func,
    handleModalInputChange: PropTypes.func,
    minStayType: PropTypes.string,
  };

  state = {
    rateValue: this.props.value,
    defaultRateValue: this.props.value,
    visible: false,
    eraseIsSelected: false,
  };

  componentDidMount() {
    const { isVisible, value } = this.props;

    const eraseIsSelected = value.some((i) => i === null);

    this.setState({
      visible: isVisible,
      eraseIsSelected,
    });
  }

  onClose = (callback) => {
    return () => {
      this.setState({
        visible: false,
      });

      callback();
    };
  };

  onChangeRateValue = (arr) => this.setState({ rateValue: arr });

  getDifferentInitialValueByDate = (rangeDates, restriction) => {
    const { ari, roomOrRateId, value } = this.props;
    const minValue = Math.min(...value);
    const initialValues = ari[roomOrRateId];
    return rangeDates.map((date) => {
      let result = 0;

      switch (restriction) {
        case "max_availability":
        case "availability":
          result = minValue;
          break;

        case "closed_to_arrival":
        case "closed_to_departure":
        case "stop_sell":
        case "stop_sell_manual":
          result = value.some((i) => i);
          break;

        case "rate":
          result = Number(_.get(initialValues, `${date}.${restriction}`, result));
          break;

        default:
          result = _.get(initialValues, `${date}.${restriction}`, result);

          break;
      }

      return result;
    });
  };

  getInitialValueWithChangesByDate = (rangeDates, restriction) => {
    const { changes, ari, roomOrRateId, value, availability } = this.props;
    return rangeDates.map((date) => {
      const valueByDate = changes[date];
      let result = 0;
      const initialValues = ari[roomOrRateId] || availability[roomOrRateId];
      const isAriRestriction = _.get(initialValues, `${date}`, result);
      if (
        restriction === "closed_to_arrival"
        || restriction === "closed_to_departure"
        || restriction === "stop_sell"
        || restriction === "stop_sell_manual"
      ) {
        result = value.some((i) => i);
      }
      if (valueByDate && Object.keys(valueByDate) === restriction) {
        result = Number(valueByDate[restriction]);
      }
      if (isAriRestriction) {
        result = Number(isAriRestriction[restriction]);
      }
      if (restriction === "availability") {
        result = Number(isAriRestriction);
      }

      return result;
    });
  };

  getInitialValueByDate = (dates, restriction = this.props.restriction) => {
    const { changes, ari, roomOrRateId, value, availability } = this.props;
    const { rateValue } = this.state;

    const rangeDates = getArrayDays(dates[0], dates[dates.length - 1]);

    if (!_.isEqual(value, rateValue)) {
      return this.getDifferentInitialValueByDate(rangeDates, restriction);
    }

    if (changes && Object.keys(changes).length > 0) {
      return this.getInitialValueWithChangesByDate(rangeDates, restriction);
    }

    return rangeDates.map((date) => {
      const initialValues = ari[roomOrRateId] || availability[roomOrRateId];
      let result = 0;
      switch (restriction) {
        case "closed_to_arrival":
        case "closed_to_departure":
        case "stop_sell":
        case "stop_sell_manual":
          result = value.some((i) => i);
          break;

        case "availability":
          result = Number(_.get(initialValues, `${date}`, result));
          break;

        case "rate":
          result = Number(_.get(initialValues, `${date}.${restriction}`, result));
          break;

        default:
          result = _.get(initialValues, `${date}.${restriction}`, result);
          break;
      }

      return result;
    });
  };

  onChangeDateValue = (date) => {
    if (date) {
      this.props.handleModalInputChange("date")(date);
      const newValue = this.getInitialValueByDate(date);

      this.setState({ rateValue: newValue, defaultRateValue: newValue });

      this.props.handleModalInputChange("value")(newValue);
    }
  };

  onChangeRestrictionValue = (val) => {
    const { date } = this.props;

    const newValue = this.getInitialValueByDate(date, val);
    this.props.handleModalInputChange("restriction")(val);

    this.setState({ rateValue: newValue, defaultRateValue: newValue });

    this.props.handleModalInputChange("value")(newValue);
  };

  onChangeBoolValue = (val) => {
    const { rateValue } = this.state;
    const newValue = rateValue.map(() => val);
    this.setState({ rateValue: newValue, defaultRateValue: newValue });
    this.props.handleModalInputChange("value")(newValue);
  };

  onOk = () => {
    const { handleOk, value, restriction } = this.props;
    const { rateValue } = this.state;
    let newValue = [];

    if (_.isEqual(value, rateValue)) {
      const minValue = Math.min(...value).toString();
      const stopSellValue = rateValue.some((i) => i);
      const valueWithNull = rateValue.some((i) => i === null);
      newValue = [];

      switch (restriction) {
        case "max_availability":
          newValue = rateValue.map((i) => (valueWithNull ? null : i));
          break;

        case "closed_to_arrival":
        case "closed_to_departure":
        case "stop_sell":
        case "stop_sell_manual":
          newValue = rateValue.map(() => stopSellValue);
          break;

        default:
          newValue = rateValue.map(() => minValue);
          break;
      }
    } else {
      switch (restriction) {
        case "closed_to_arrival":
        case "closed_to_departure":
        case "stop_sell":
        case "stop_sell_manual":
        case "max_availability":
          newValue = [...rateValue];
          break;

        default:
          newValue = rateValue.map((item) => item.toString());
          break;
      }
    }

    this.props.handleModalInputChange("value")(newValue);

    return this.onClose(handleOk());
  };

  onKeyPress = (event) => {
    const { restriction } = this.props;
    const { rateValue } = this.state;
    const [isValid] = validateRestriction(restriction, rateValue);

    if (event?.key === "Enter" && isValid) {
      this.onOk();
    }
  };

  onToggleErase = () => {
    const { handleModalInputChange } = this.props;
    const { eraseIsSelected, rateValue } = this.state;

    this.setState({
      eraseIsSelected: !eraseIsSelected,
    });

    const newRestrictionValue = !eraseIsSelected ? null : 0;
    const newValue = rateValue.map(() => newRestrictionValue);
    this.onChangeRateValue(newValue);
    handleModalInputChange("value")(newValue);
  };

  disabledDate = (current) => {
    const { defaultCurrentDate } = this.props;

    return current.isBefore(defaultCurrentDate, "day");
  };

  restrictionTypes = (selectedRestriction) => {
    const { minStayType } = this.props;

    if (selectedRestriction === AVAILABILITY_RESTRICTION) {
      return [AVAILABILITY_RESTRICTION];
    }

    return VISIBLE_RESTRICTIONS.filter(
      (restriction) => restriction !== AVAILABILITY_RESTRICTION && restriction !== BOOKED_RESTRICTION,
    ).filter((restriction) => {
      switch (minStayType) {
        case "arrival":
          return restriction !== "min_stay_through";

        case "through":
          return restriction !== "min_stay_arrival";

        default:
          return true;
      }
    });
  };

  translateMinStay = (restriction) => {
    const { minStayType } = this.props;

    if (minStayType === "arrival" && restriction === "min_stay_arrival") {
      return "min_stay";
    }

    if (minStayType === "through" && restriction === "min_stay_through") {
      return "min_stay";
    }

    return restriction;
  };

  isDerived = (restriction, ratePlan) => {
    if (restriction === "stop_sell_manual") {
      restriction = "stop_sell";
    }

    return ratePlan[`inherit_${restriction}`];
  };

  render() {
    const { t, restriction, date, ratePlan, roomType, isMobile, handleCancel } = this.props;
    const { visible, eraseIsSelected, rateValue, defaultRateValue } = this.state;
    const { title: rateTitle, occupancy } = ratePlan;
    const { title: roomTitle } = roomType;
    const isDerived = this.isDerived(restriction, ratePlan);
    const derivedError = isDerived ? t("inventory_page:modal:derived_error") : null;

    const dataCy = "modal_input";

    const [isValid, error] = validateRestriction(restriction, rateValue);

    return (
      <ChannexModal
        title={t("inventory_page:modal:title")}
        visible={visible}
        onOk={this.onOk}
        onCancel={this.onClose(handleCancel)}
        okButtonProps={{
          disabled: !isValid || isDerived,
        }}
      >
        <Form.Item {...horizontalFormItemLayout} label={t("inventory_page:modal:room_type_label")}>
          {roomTitle}
        </Form.Item>
        {rateTitle && ( // Need check for case when user clicks on room cell
          <Form.Item
            {...horizontalFormItemLayout}
            label={t("inventory_page:modal:rate_plan_label")}
          >
            <InventoryRatePlan title={rateTitle} occupancy={occupancy} />
          </Form.Item>
        )}
        <Form.Item {...horizontalFormItemLayout} label={t("inventory_page:modal:date_range_label")}>
          <RangePicker
            dataCy="date_range"
            defaultValue={date}
            value={date}
            isMobile={isMobile}
            onChange={this.onChangeDateValue}
            disabledDate={this.disabledDate}
          />
        </Form.Item>
        <Form.Item
          {...horizontalFormItemLayout}
          label={t("inventory_page:modal:restriction_label")}
          validateStatus={isDerived ? "error" : "success"}
          help={derivedError}
        >
          <Select
            data-cy="modal_restriction"
            defaultValue={restriction}
            value={restriction}
            onChange={this.onChangeRestrictionValue}
          >
            {this.restrictionTypes(restriction)
              .sort(sortRestrictionsByRepresentation(t))
              .map((restrictionType) => (
                <Select.Option key={restrictionType} value={restrictionType}>
                  {t(`general:restrictions:${this.translateMinStay(restrictionType)}`)}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <InventoryValueOverrideController
          valid={isValid && !isDerived}
          booleanRestrictions={booleanRestrictions}
          dataCy={dataCy}
          eraseIsSelected={eraseIsSelected}
          onToggleErase={this.onToggleErase}
          onKeyPress={this.onKeyPress}
          horizontalFormItemLayout={horizontalFormItemLayout}
          {...this.props}
          value={rateValue}
          defaultValue={defaultRateValue}
          onChangeRateValue={this.onChangeRateValue}
          error={error}
        />
        {booleanRestrictions.indexOf(restriction) !== -1 && (
          <Form.Item
            {...horizontalFormItemLayout}
            label={t("inventory_page:modal:boolean_value_label")}
          >
            <InventoryValueSwitchController
              onChangeBoolValue={this.onChangeBoolValue}
              value={this.props.value}
            />
          </Form.Item>
        )}
      </ChannexModal>
    );
  }
}

export default InventoryValueOverrideModal;
