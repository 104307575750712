import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import store from "store";

import Loading from "components/loading/loading";

import ApaleoSettings from "../../../../application_settings/apaleo";
import { StepBody } from "../step_body";
import { StepTitle } from "../step_title";

const { Applications } = store;

const useInstalledApplications = () => {
  return useQuery({
    queryKey: ["applications"],
    queryFn: () => Applications.loadInstalled(),
  });
};

export const SettingsStep = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { data, enableNextButton } = props;

  const applications = useInstalledApplications();
  const formRef = useRef();

  useImperativeHandle(ref, () => ({
    proceed: async () => {
      await formRef.current.submit();
    },
  }));

  useEffect(() => {
    enableNextButton(false);
  }, [enableNextButton]);

  if (applications.isLoading) {
    return <Loading />;
  }

  const { applicationInstallationId } = data;

  const installation = applications.data.find((app) => app.id === applicationInstallationId);

  return (
    <div>
      <StepTitle>{t("apaleo_import:titles:settings")}</StepTitle>
      <StepBody>
        <ApaleoSettings
          ref={formRef}
          embedded
          installation={installation}
          onApaleoMappingLoaded={() => {
            enableNextButton(true);
          }}
        />
      </StepBody>
    </div>
  );
});
