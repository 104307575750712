import React from "react";
import { useTranslation } from "react-i18next";

import GroupPerPersonAutoRate from "components/forms/groups/group_per_person_auto_rate";
import GroupPerPersonDerivedRate from "components/forms/groups/group_per_person_derived_rate";
import GroupPerPersonManualRate from "components/forms/groups/group_per_person_manual_rate";
import InputDerivedRate from "components/forms/items/input_derived_rate";
import InputRate from "components/forms/items/input_rate";

export default function GroupRateFieldComponent(props) {
  const { t } = useTranslation();
  const { model, disabled, errors, onChange } = props;
  const code = [
    String(model.sell_mode),
    String(model.is_derived && model.inherit_rate),
    model.rate_mode,
  ].join(":");
  const isDerivedInputDisabled = Boolean(model.rate_category_id) || disabled;
  let result = null;

  switch (code) {
    case "per_room:false:manual":
      result = (
        <InputRate t={t} errors={errors} disabled={disabled} model={model} onChange={onChange} />
      );
      break;
    case "per_room:false:derived":
      result = (
        <InputRate t={t} errors={errors} disabled={disabled} model={model} onChange={onChange} />
      );
      break;
    case "per_room:false:auto":
      result = (
        <InputRate t={t} errors={errors} disabled={disabled} model={model} onChange={onChange} />
      );
      break;
    case "per_room:true:manual":
      result = (
        <InputDerivedRate
          t={t}
          errors={errors}
          disabled={isDerivedInputDisabled}
          model={model}
          onChange={onChange}
        />
      );
      break;
    case "per_room:true:derived":
      result = (
        <InputDerivedRate
          t={t}
          errors={errors}
          disabled={isDerivedInputDisabled}
          model={model}
          onChange={onChange}
        />
      );
      break;
    case "per_room:true:auto":
      result = (
        <InputDerivedRate
          t={t}
          errors={errors}
          disabled={isDerivedInputDisabled}
          model={model}
          onChange={onChange}
        />
      );
      break;

    case "per_person:false:manual":
      result = (
        <GroupPerPersonManualRate
          t={t}
          errors={errors}
          disabled={disabled}
          model={model}
          onChange={onChange}
        />
      );
      break;
    case "per_person:false:derived":
      result = (
        <GroupPerPersonDerivedRate
          t={t}
          errors={errors}
          disabled={disabled}
          model={model}
          onChange={onChange}
        />
      );
      break;
    case "per_person:false:auto":
      result = (
        <GroupPerPersonAutoRate
          t={t}
          errors={errors}
          disabled={disabled}
          model={model}
          onChange={onChange}
        />
      );
      break;

    case "per_person:true:manual":
      result = (
        <GroupPerPersonManualRate
          t={t}
          errors={errors}
          disabled={disabled}
          model={model}
          onChange={onChange}
        />
      );
      break;
    case "per_person:true:derived":
      result = (
        <GroupPerPersonDerivedRate
          t={t}
          errors={errors}
          disabled={disabled}
          model={model}
          onChange={onChange}
        />
      );
      break;
    case "per_person:true:auto":
      result = (
        <GroupPerPersonAutoRate
          t={t}
          errors={errors}
          disabled={disabled}
          model={model}
          onChange={onChange}
        />
      );
      break;
    case "per_person:true:cascade":
      result = (
        <InputDerivedRate
          t={t}
          errors={errors}
          disabled={isDerivedInputDisabled}
          model={model}
          onChange={onChange}
        />
      );
      break;
    default:
      break;
  }

  return result;
}
