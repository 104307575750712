import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawerRoutable from "drawers/channex_drawer_routable";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import RoomCategoryAssignForm from "./room_category_assign_form";

export default function RoomCategoryAssignFormDrawer({ params, onSubmit }) {
  const [roomType, setRoomType] = useState(null);
  const { t } = useTranslation();
  const { roomId } = params;

  const title = roomType
    ? t("rooms_page:assign_rate_category", { title: roomType.title })
    : t("general:loading");

  const handleRoomTypeLoad = useCallback((newRoomType) => {
    setRoomType(newRoomType);
  }, []);

  return (
    <ChannexDrawerRoutable title={title}>
      {({ handleCloseFromContent }) => (
        <DrawerFormWrapper>
          <RoomCategoryAssignForm
            roomTypeId={roomId}
            onRoomTypeLoad={handleRoomTypeLoad}
            onSubmit={onSubmit}
            onClose={handleCloseFromContent}
          />
        </DrawerFormWrapper>
      )}
    </ChannexDrawerRoutable>
  );
}
