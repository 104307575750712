import React from "react";
import PropTypes from "prop-types";

import FormInput from "components/forms/inputs/form_input";

import ChooseRoomTypeMessage from "../items/choose_room_type_message";

function rateOptionTemplate(args) {
  return function (el) {
    const { model, t, disabled, onRateOptionChange } = args;

    return (
      <FormInput
        name={`rate_for_${el.occupancy}_person`}
        key={`OccupancyOption_${el.occupancy}`}
        view="horizontal"
        type="text"
        placeholder={t("rates_page:form:rate_placeholder")}
        label={t("rates_page:form:rate_for", { count: el.occupancy })}
        addonAfter={model.currency}
        defaultValue={el.rate}
        onChange={onRateOptionChange(el.occupancy)}
        disabled={disabled}
      />
    );
  };
}

function optionSorter(optionA, optionB) {
  return optionA.occupancy - optionB.occupancy;
}

function onRateOptionChangeCallback(model, onChange) {
  return (occupancy) => (value) => {
    onChange(
      "options",
      model.options.map((option) => {
        let result = option;

        if (option.occupancy === occupancy) {
          result = {
            ...option,
            rate: value,
          };
        }

        return result;
      }),
    );
  };
}

function GroupPerPersonManualRate(props) {
  const { t, model, onChange } = props;
  const onRateOptionChange = onRateOptionChangeCallback(model, onChange);
  const renderRateOption = rateOptionTemplate({ ...props, onRateOptionChange });

  let result = <ChooseRoomTypeMessage t={t} />;

  if (model.room_type_id) {
    result = model.options.sort(optionSorter).map(renderRateOption);
  }

  return result;
}

GroupPerPersonManualRate.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  onChange: PropTypes.func,
};

export default GroupPerPersonManualRate;
