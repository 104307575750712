import config from "../../config";
import stringifyArguments from "../utils/stringify_arguments";

const statusToSubTypes = {
  400: "bad_request",
  401: "unauthenticated",
  403: "forbidden",
  404: "not_found",
  422: "validation_error",
  429: "too_many_requests",
  500: "internal_server_error",
  502: "bad_gateway",
  503: "service_unavailable",
  504: "gateway_timeout",
  default: "unknown_error",
};

function createHttpError(status, { subType, body }) {
  const errorSubType = subType || (statusToSubTypes[status] || statusToSubTypes.default);

  const error = new Error(errorSubType);

  error.type = "http";
  error.status = status;
  error.subType = errorSubType;

  // defined explicitly for code autocomplete
  error.isHttp = true;
  error.isBadRequest = errorSubType === "bad_request";
  error.isUnauthenticated = errorSubType === "unauthenticated";
  error.isForbidden = errorSubType === "forbidden";
  error.isNotFound = errorSubType === "not_found";
  error.isValidationError = errorSubType === "validation_error";
  error.isTooManyRequests = errorSubType === "too_many_requests";
  error.isInternalServerError = errorSubType === "internal_server_error";
  error.isBadGateway = errorSubType === "bad_gateway";
  error.isServiceUnavailable = errorSubType === "service_unavailable";
  error.isGatewayTimeout = errorSubType === "gateway_timeout";
  error.isUnknownError = errorSubType === "unknown_error";

  if (error.isValidationError) {
    error.body = body;
    Object.assign(error, body); // compatibility with existing error handling code
  }

  return error;
}

export function throwHttpError(status, { subType, body } = {}) {
  throw createHttpError(status, { subType, body });
}

export default class HTTPTransport {
  constructor(settings, storage) {
    this.settings = settings;
    this.storage = storage;
    this.refreshRequest = null;
  }

  async send(method, endpoint, data, options = {}) {
    const { refreshToken = true, signal, redirect = "follow" } = options;
    const response = await this[`_${method.toLowerCase()}`](endpoint, data, { signal, redirect });

    this.setDeviceCookie(response);

    let body;
    if (response.headers.get("Content-Type")?.includes("application/json")) {
      body = await response.json();
    } else {
      body = await response.text();
    }

    if (response.status === 401 && refreshToken) {
      await this._refreshTokens();
      return this.send(method, endpoint, data);
    }

    if (response.status === 401 && !refreshToken) {
      throwHttpError(response.status, { body });
    }

    if (!response.ok) {
      throwHttpError(response.status, { body });
    }

    return body;
  }

  _refreshTokens() {
    // To eliminate multiple parallel refresh requests
    if (this.refreshRequest) {
      return this.refreshRequest;
    }

    const { session } = this.storage.getState();

    this.refreshRequest = fetch(this.url("refresh"), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${session.refreshToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throwHttpError(response.status, { subType: "authentication_expired" });
        }

        return response.json();
      })
      .then(({ data }) => {
        this.refreshRequest = null;

        this.storage.sessionAdd(data.attributes);
      })
      .catch((error) => {
        this.refreshRequest = null;

        this.storage.logout();

        throw error;
      });

    return this.refreshRequest;
  }

  url(endpoint, params = null) {
    const protocol = this.settings.secure ? "https" : "http";

    return `${protocol}://${this.settings.server}/api/v1/${endpoint}${stringifyArguments(params)}`;
  }

  _post(endpoint, data, options = {}) {
    const { signal, redirect } = options;

    return fetch(this.url(endpoint), {
      method: "POST",
      headers: this._headers(),
      body: JSON.stringify(data),
      signal,
      redirect,
    });
  }

  _put(endpoint, data, options = {}) {
    const { signal } = options;

    return fetch(this.url(endpoint), {
      method: "PUT",
      headers: this._headers(),
      body: JSON.stringify(data),
      signal,
    });
  }

  _get(endpoint, filters, options = {}) {
    const { signal } = options;

    return fetch(this.url(endpoint, filters), {
      method: "GET",
      headers: this._headers(),
      signal,
    });
  }

  _delete(endpoint, filters, options = {}) {
    const { signal } = options;

    return fetch(this.url(endpoint, filters), {
      method: "DELETE",
      headers: this._headers(),
      signal,
    });
  }

  _headers() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-ui-version": config.APP_VERSION,
    };

    const { session } = this.storage.getState();

    if (session.authToken) {
      headers.Authorization = `Bearer ${session.authToken}`;
    }

    const xDeviceUID = this._getCookie("x-device-uid");
    if (xDeviceUID) {
      headers["x-device-uid"] = xDeviceUID;
    }

    return headers;
  }

  _getCookie(name) {
    const cookieObj = new URLSearchParams(document.cookie.replaceAll("&", "%26").replaceAll("; ", "&"));
    return cookieObj.get(name);
  }

  setDeviceCookie(response) {
    const deviceUID = response.headers.get("x-device-uid");
    if (deviceUID) {
      document.cookie = `x-device-uid = ${deviceUID}; max-age=2952000; path=/;`;
    }

    return response;
  }
}
