import { useEffect, useState } from "react";
import { captureException } from "@sentry/react";
import store from "store";

const { Properties } = store;

export default function useProperty(propertyId) {
  const [property, setProperty] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadProperty = async () => {
      if (!propertyId) {
        return;
      }

      let loadedProperty;

      try {
        loadedProperty = await Properties.find(propertyId);
        setProperty(loadedProperty);
      } catch (apiError) {
        setError(apiError);
      } finally {
        setIsLoading(false);
      }
    };

    loadProperty().catch(captureException);
  }, [propertyId]);

  return { property, error, isLoading };
}
