export default [
  {
    key: "en",
    value: "English",
  },
  {
    key: "es",
    value: "Spanish",
  },
  {
    key: "pt",
    value: "Portugal",
  },
  {
    key: "ru",
    value: "Русский",
  },
  {
    key: "de",
    value: "Deutsch",
  },
  {
    key: "el",
    value: "Ελληνικά",
  },
  {
    key: "it",
    value: "Italiano",
  },
  {
    key: "hu",
    value: "Hungarian",
  },
];
