import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { ReloadOutlined } from "@ant-design/icons";

import styles from "./resend_link.module.css";

class ResendLink extends Component {
  render() {
    const { t, onClick } = this.props;

    return (
      <div className={styles.resend_link}>
        <a href="#resend" onClick={onClick}>
          <ReloadOutlined /> {t("bookings_page:booking_view_dialog:resend:link")}
        </a>
      </div>
    );
  }
}

export default withTranslation()(ResendLink);
