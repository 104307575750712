import attributesExtractor from "../utils/attributes_extractor";
import { convertToSnakeCase } from "../utils/case_converter";

let transport;
let storage;

const ENDPOINT = "admin/billing_accounts";

export default class AdminBillingAccounts {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(filter = {}, pagination = { page: 1, limit: 10 }, order = { companyName: "desc" }) {
    return transport.send("GET", ENDPOINT, { filter, pagination, order }).then((response) => {
      storage.adminBillingAccountsLoad(response.data, response.meta);

      return response;
    });
  }

  options(filter = {}) {
    return transport.send("GET", `${ENDPOINT}/options`, { filter }).then((response) => {
      return response.data.map((el) => {
        return { companyName: el.attributes.company_name, id: el.id };
      });
    });
  }

  find(id) {
    return transport.send("GET", `${ENDPOINT}/${id}`).then((response) => {
      storage.adminBillingAccountsAdd(response.data);
      return response;
    });
  }

  update(attrs) {
    return transport
      .send("PUT", `${ENDPOINT}/${attrs.id}`, { billing_account: attrs })
      .then((response) => {
        storage.adminBillingAccountsAdd(response.data);
        return response;
      });
  }

  remove(attrs) {
    return transport.send("DELETE", `${ENDPOINT}/${attrs.id}`).then((response) => {
      storage.adminBillingAccountsDrop(attrs);
      return response;
    });
  }

  sync(id) {
    return transport.send("POST", `${ENDPOINT}/${id}/sync`).then((response) => {
      return response;
    });
  }

  memberships(id) {
    return transport.send("GET", `${ENDPOINT}/${id}/memberships`).then((response) => {
      return response;
    });
  }

  removeMembership(billingAccountId, membershipId) {
    return transport
      .send("DELETE", `${ENDPOINT}/${billingAccountId}/memberships/${membershipId}`)
      .then((response) => {
        return response;
      });
  }

  properties(id) {
    return transport.send("GET", `${ENDPOINT}/${id}/properties`).then((response) => {
      return response;
    });
  }

  createSubscription(billingAccountId, attrs) {
    const body = convertToSnakeCase({
      subscription: convertToSnakeCase(attrs),
    });

    return transport
      .send("POST", `${ENDPOINT}/${billingAccountId}/subscriptions`, body)
      .then(({ data }) => {
        return attributesExtractor(data);
      });
  }
}
