import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import ChannexDrawer from "drawers/channex_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";
import withRouter from "routing/with_router";

import ApaleoSettings from "./apaleo";
import AthenaSettings from "./athena";
import BusyRoomsSettings from "./busy_rooms";
import ChannexPCISettings from "./channex_pci";
import GoldenUpSettings from "./golden_up";
import HotelNabeSettings from "./hotel_nabe";
import MakeSettings from "./make";
import MewsSettings from "./mews";
import OperaSettings from "./opera";
import RateTigerSettings from "./rate_tiger";
import RoomCloudSettings from "./room_cloud";
import ShalomSettings from "./shalom";
import StaahSettings from "./staah";
import WiseSettings from "./wise";
import YieldPlanetSettings from "./yield_planet";
import ZapierSettings from "./zapier";

const settingsComponents = {
  apaleo: ApaleoSettings,
  athena: AthenaSettings,
  busy_rooms: BusyRoomsSettings,
  golden_up: GoldenUpSettings,
  hotel_nabe: HotelNabeSettings,
  shalom: ShalomSettings,
  wise: WiseSettings,
  yield_planet: YieldPlanetSettings,
  mews: MewsSettings,
  mews_reserva: MewsSettings,
  opera: OperaSettings,
  rate_tiger: RateTigerSettings,
  room_cloud: RoomCloudSettings,
  staah: StaahSettings,
  channex_pci: ChannexPCISettings,
  zapier: ZapierSettings,
  make_com: MakeSettings,
};

function ApplicationSettings(props) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [installation, setInstallation] = useState(null);
  const { userAppRoutes } = useRouting();
  const {
    navigate,
    params: { installationId },
    applications,
  } = props;

  const onClose = () => {
    setVisible(false);
    setTimeout(() => {
      navigate(pathBuilder(userAppRoutes.applications, {}));
    }, 300);
  };

  useEffect(function setDrawerVisibility() {
    setVisible(true);
  }, []);

  useEffect(
    function loadInstallation() {
      if (applications && applications.installed) {
        setInstallation(applications.installed[installationId]);
      }
    },
    [applications, installationId],
  );

  let title;
  let content;

  if (installation) {
    title = t(`applications_page:${installation.applicationCode}:settings_header`);
    content = React.createElement(settingsComponents[installation.applicationCode], {
      installation,
      onClose,
    });
  } else {
    title = t("general:loading");
    content = null;
  }
  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose}>
      {({ _handleCloseFromContent }) => (
        <DrawerFormWrapper withActions>{content}</DrawerFormWrapper>
      )}
    </ChannexDrawer>
  );
}

const mapStateToProps = ({ applications }) => {
  return { applications };
};

export default withRouter(connect(mapStateToProps)(ApplicationSettings));
