import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Form } from "antd";
import store from "store";

import ErrorBoundary from "components/error_boundary";
import SubmitButton from "components/forms/buttons/submit_button";
import GroupAssignRateSettings from "components/forms/groups/group_assign_rate_settings";
import InputRateCategory from "components/forms/items/input_rate_category";
import Loading from "components/loading";

import withLogic from "containers/rate_category_assign_form_logic";

import showErrorMessage from "utils/show_error_message";

import styles from "styles/form_in_drawer.module.css";

const { RateCategories, RoomTypes } = store;

const EMPTY_MODEL = {
  room_type_id: undefined,
  property_id: undefined,
  rate_category_id: undefined,
  rate: 0,
};

class RoomCategoryAssignForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    t: PropTypes.func.isRequired,
    rateCategories: PropTypes.array,
    roomType: PropTypes.object,
  };

  static mutators = {
    rate_category_id: RoomCategoryAssignForm.onRateCategoryIdChange,
  };

  static generateOccupancyOptions(value, occupancy) {
    return new Array(occupancy).fill(null).map((el, index) => {
      return {
        occupancy: index + 1,
        rate: 0,
        derived_option: value.rate_mode === "manual" ? null : { rate: [] },
      };
    });
  }

  static onRateCategoryIdChange(state, updates, model, value) {
    const { rateCategories, roomType } = state;
    const rateCategory = rateCategories.filter((el) => el.id === value)[0];

    updates.value = Object.assign(updates.value, {
      rate_category_id: rateCategory.id,
      rate_mode: rateCategory.rate_mode,
      sell_mode: rateCategory.sell_mode,
      derived_option: rateCategory.derived_option || { rate: [] },
    });

    if (rateCategory.sell_mode === "per_person") {
      updates.value.options = RoomCategoryAssignForm.generateOccupancyOptions(
        updates.value,
        roomType.occ_adults,
      );
    }
    updates.value.primary_occupancy = roomType.default_occupancy;

    return updates;
  }

  static contextType = ErrorBoundary.Context;

  state = {
    isVisible: true,
    loading: true,
    errors: {},
    value: {},
    roomType: null,
    rateCategories: null,
    submitInProgress: false,
  };

  componentDidMount() {
    const { roomTypeId } = this.props;

    this.findRoomType({ roomTypeId })
      .then(this.loadRateCategories)
      .then(this.prepareValue)
      .then(this.assignState)
      .catch(this.handleAsyncError);
  }

  handleAsyncError = (error) => {
    const { handleError } = this.context;

    handleError(error);
  };

  findRoomType = (payload) => {
    const { onRoomTypeLoad } = this.props;
    const { roomTypeId } = payload;

    return RoomTypes.find(roomTypeId).then((roomType) => {
      onRoomTypeLoad(roomType);

      return {
        ...payload,
        roomType,
      };
    });
  };

  loadRateCategories = (payload) => {
    return new Promise((resolve, reject) => {
      if (payload.roomType) {
        return RateCategories.list().then((response) => {
          resolve({
            ...payload,
            rateCategories: response.data.map((el) => el.attributes),
          });
        });
      }

      return reject(payload);
    });
  };

  prepareValue = (payload) => {
    return new Promise((resolve) => {
      const value = Object.assign(EMPTY_MODEL, {
        property_id: payload.roomType.property_id,
        room_type_id: payload.roomType.id,
      });

      resolve({
        ...payload,
        value,
      });
    });
  };

  assignState = (payload) => {
    this.setState({
      loading: false,
      value: payload.value,
      roomType: payload.roomType,
      rateCategories: payload.rateCategories,
    });
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  onSuccess = () => {
    this.props.onClose();
  };

  onError = (response) => {
    const { t } = this.props;

    this.setState({ loading: false });

    if (response && response.errors && response.errors.code) {
      if (response.errors.code === "validation_error") {
        this.setState({
          errors: response.errors.details,
        });
        if (response.errors.details.title) {
          showErrorMessage(t("general:errors:duplications_is_not_allowed"));
        }
      } else {
        showErrorMessage(t(`general:${response.errors.code}`));
      }
    } else {
      showErrorMessage(t("general:undefined_error"));
    }
  };

  onChange = (field, val) => {
    const { value } = this.state;
    let updates = {
      value: {
        ...value,
        [field]: val,
      },
    };

    if (
      RoomCategoryAssignForm.mutators[field]
      && typeof RoomCategoryAssignForm.mutators[field] === "function"
    ) {
      updates = RoomCategoryAssignForm.mutators[field](this.state, updates, value, val);
    }

    this.setState(updates);
  };

  onSubmit = () => {
    const { onSubmit } = this.props;

    this.setState({ submitInProgress: true });

    const { value } = this.state;

    onSubmit(value, this.onSuccess, this.onError);
  };

  render() {
    const { t } = this.props;
    const { loading, value, errors, rateCategories, roomType, submitInProgress } = this.state;

    if (loading) {
      return <Loading />;
    }

    const rateCategory = rateCategories.filter((el) => el.id === value.rate_category_id)[0];
    const args = {
      t,
      errors,
      roomType,
      rateCategory,
      model: value,
      onChange: this.onChange,
    };

    return (
      <Form onFinish={this.onSubmit}>
        <InputRateCategory {...args} rateCategories={rateCategories} />
        {value.rate_category_id && <GroupAssignRateSettings {...args} />}
        <div className={styles.actions}>
          <SubmitButton loading={submitInProgress}>
            {t("rate_categories_page:submit_button")}
          </SubmitButton>
        </div>
      </Form>
    );
  }
}

export default withTranslation()(withLogic(RoomCategoryAssignForm));
