import { convertToCamelCase } from "store/utils/case_converter";

let transport;

const ENDPOINT = "state_changes";

export default class StateChanges {
  constructor(container) {
    transport = container.transport;
  }

  forRate(propertyId, ratePlanId, date, restriction) {
    return transport.send("GET", `${ENDPOINT}`, {
      "filter[property_id]": propertyId,
      "filter[rate_plan_id]": ratePlanId,
      "filter[date]": date,
      "filter[change][has]": restriction,
      "pagination[limit]": "100",
      "order[inserted_at]": "desc",
    }).then((response) => {
      const data = convertToCamelCase(response.data);
      return data;
    });
  }

  forRoom(propertyId, roomTypeId, date, restriction) {
    return transport.send("GET", `${ENDPOINT}`, {
      "filter[property_id]": propertyId,
      "filter[room_type_id]": roomTypeId,
      "filter[date]": date,
      "filter[change][has]": restriction,
      "pagination[limit]": "100",
      "order[inserted_at]": "desc",
    }).then((response) => {
      const data = convertToCamelCase(response.data);
      return data;
    });
  }
}
