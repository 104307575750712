import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Tabs } from "antd";
import store from "store";

import ErrorBoundary from "components/error_boundary";
import Loading from "components/loading";

import pathBuilder from "routing/path_builder";
import withRouter from "routing/with_router";

import alphabetSort from "utils/alphabet_sort";
import EventEmitter from "utils/event_emitter";
import showErrorMessage from "utils/show_error_message";

import ContentForm from "./components/content_form";
import GeneralForm from "./components/general_form";

// The RoomSpaces feature was deactivated due to a product decision
// import RoomSpacesTable from "./components/room_spaces_table";

const { RoomTypes, RoomFacilities } = store;

const DEFAULT_TAB = "general";
const SORTER_FACILITIES = "representation";

class RoomForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.string,
    activeProperty: PropTypes.string,
  };

  static contextType = ErrorBoundary.Context;

  state = {
    modelLoading: true,
    submitInProgress: false,
    errors: {},
    formValue: {},
    facilitiesOptions: [],
  };

  componentDidMount() {
    this.initModel();
  }

  initModel = () => {
    const { t } = this.props;

    Promise.all([this.initFormValue(), RoomFacilities.options()])
      .then(([formValue, rawFacilitiesOptions]) => {
        const facilitiesOptions = rawFacilitiesOptions
          .map((el) => ({
            value: el.id,
            representation: el.title,
            category: t(`facility:${el.category}`),
          }))
          .sort(alphabetSort(SORTER_FACILITIES));

        this.setState({
          modelLoading: false,
          formValue,
          facilitiesOptions,
        });
      })
      .catch(this.handleAsyncError);
  };

  handleAsyncError = (error) => {
    const { handleError } = this.context;

    handleError(error);
  };

  initFormValue = () => {
    const { id, activeProperty } = this.props;

    if (!id) {
      return Promise.resolve({
        property_id: activeProperty || undefined,
        room_kind: "room",
      });
    }

    return RoomTypes.find(id).then((roomType) => {
      const facilities = Object.keys(roomType.facilities);

      const formValue = {
        ...roomType,
        facilities,
      };

      return formValue;
    });
  };

  onError = (response) => {
    const { t } = this.props;
    this.setState({ submitInProgress: false });

    if (response && response.errors && response.errors.code) {
      if (response.errors.code === "validation_error") {
        this.setState({
          errors: response.errors.details,
        });
      } else {
        showErrorMessage(t(`general:${response.errors.code}`));
      }
    } else {
      showErrorMessage(t("general:undefined_error"));
    }
  };

  handleChange = (key) => (fieldValue) => {
    this.setState(({ formValue }) => ({
      formValue: {
        ...formValue,
        [key]: fieldValue,
      },
    }));
  };

  handleTabChange = (selectedTab) => {
    const { id, navigate, routes, routable } = this.props;

    if (!id || !routable) {
      return;
    }

    const tabsToRoutes = {
      general: pathBuilder(routes.userAppRoutes.rooms.edit, { roomId: id, section: "general" }),
      content: pathBuilder(routes.userAppRoutes.rooms.edit, { roomId: id, section: "content" }),
      // spaces: pathBuilder(routes.userAppRoutes.rooms.edit, { roomId: id, section: "spaces" }),
    };

    const path = tabsToRoutes[selectedTab];

    navigate(path);
  };

  handleSubmit = () => {
    const { onClose } = this.props;
    const { formValue } = this.state;

    this.setState({ submitInProgress: true });

    const method = formValue.id ? RoomTypes.update : RoomTypes.create;

    method(formValue)
      .then((response) => {
        onClose(response);
        EventEmitter.trigger("room_type:created");
      })
      .catch((error) => {
        this.onError(error);
      })
      .finally(() => {
        this.setState({ submitInProgress: false });
      });
  };

  render() {
    const { submitInProgress, modelLoading, errors, formValue, facilitiesOptions } = this.state;
    const { t, activeTab = DEFAULT_TAB, propertiesOptions } = this.props;

    if (modelLoading) {
      return <Loading />;
    }

    const items = [{
      label: <span data-cy="genaral_tab">{t("rooms_page:tab_keys:general")}</span>,
      key: "general",
      children: (
        <GeneralForm
          value={formValue}
          errors={errors}
          properties={propertiesOptions}
          isLoading={submitInProgress}
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
        />
      ),
    }, {
      label: <span data-cy="content_tab">{t("rooms_page:tab_keys:content")}</span>,
      key: "content",
      children: (
        <ContentForm
          value={formValue}
          errors={errors}
          facilitiesOptions={facilitiesOptions}
          isLoading={submitInProgress}
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
        />
      ),
    }];

    // if (id) {
    //   items.push({
    //     label: <span data-cy="spaces_tab">{t("rooms_page:tab_keys:spaces")}</span>,
    //     key: "spaces",
    //     children: (
    //       <RoomSpacesTable roomId={formValue.id} />
    //     ),
    //   });
    // }

    return (
      <Tabs items={items} defaultActiveKey={activeTab} onChange={this.handleTabChange} />
    );
  }
}

const mapStateToProps = ({ session, properties }) => {
  return {
    propertiesOptions: properties.options,
    activeProperty: session.activeProperty,
  };
};

export default withRouter(withTranslation()(connect(mapStateToProps)(RoomForm)));
