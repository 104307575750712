import React from "react";
import PropTypes from "prop-types";

import FormSelect from "components/forms/inputs/form_select";

function handleChangeCallback(callback, field) {
  return (value) => {
    callback(field, value);
  };
}

function optionsGenerator(values) {
  return values.map((el) => {
    return {
      value: el.id,
      representation: el.title,
    };
  });
}

function InputRateCategory(props) {
  const { t, model, errors, onChange, rateCategories } = props;
  const onHandleChange = handleChangeCallback(onChange, "rate_category_id");
  const options = optionsGenerator(rateCategories);

  return (
    <FormSelect
      view="horizontal"
      placeholder={t("rates_page:form:rate_category_placeholder")}
      label={t("rates_page:form:rate_category_label")}
      value={model.rate_category_id}
      defaultValue={model.rate_category_id}
      onChange={onHandleChange}
      errors={errors ? errors.rate_category_id : null}
      options={options}
    />
  );
}

InputRateCategory.propTypes = {
  t: PropTypes.func,
  model: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  rateCategories: PropTypes.array,
};

export default InputRateCategory;
