import relationshipsExtractor from "../utils/relationships_extractor";

let transport;
let storage;

const ENDPOINT = "channel_events";

export default class ChannelEvents {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(filter = {}, pagination = {}, order = {}) {
    return transport.send("GET", ENDPOINT, { filter, pagination, order }).then((response) => {
      const events = response.data.map((event) => {
        event.attributes = relationshipsExtractor(event);
        return event;
      });

      storage.channelEventsLoad(events, response.meta);
      return response;
    });
  }

  find(event_id) {
    return transport.send("GET", `${ENDPOINT}/${event_id}`).then(({ data }) => {
      return relationshipsExtractor(data);
    });
  }

  loadLogs(eventId, pagination) {
    return transport.send("GET", `${ENDPOINT}/${eventId}/logs`, { pagination }).then((response) => {
      return response;
    });
  }

  resolveIssue(event_id, solutions) {
    return transport
      .send("POST", `${ENDPOINT}/${event_id}/resolve`, { solutions })
      .then((response) => {
        return response;
      });
  }

  ignoreIssue(event_id) {
    return transport.send("POST", `${ENDPOINT}/${event_id}/ignore`).then((response) => {
      return response;
    });
  }
}
