import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CheckCircleOutlined, LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import { Form, Radio, Tag } from "antd";

import APP_MODES from "config/constants/app_modes";
import channelCodes from "config/constants/channels/channel_codes";
import currencyOptions from "config/constants/currency_options";
import { horizontalFormItemLayout } from "config/constants/layouts/form";

import CopyToClipboard from "components/forms/buttons/copy_to_clipboard/copy_to_clipboard";
import FormInput from "components/forms/inputs/formik/form_input";
import FormSelect from "components/forms/inputs/formik/form_select";
import FormTableSelect from "components/forms/inputs/formik/form_table_select";
import FormItem from "components/forms/items/form_item";

import ConnectionSettings from "../connection_settings/connection_settings";
import ConnectionSettingsForm from "../connection_settings_form";

import AirBnbAuthComponent from "./auth_components/air_bnb";
import HotelbedsContractSelect from "./auth_components/hotelbeds";
import VRBO from "./auth_components/vrbo";
import BookingEngineLink from "./booking_engine_link";

import componentStyles from "../../channel_management.module.css";
import settingsStyles from "../connection_settings/connection_settings_components.module.css";
import styles from "./channel_sub_form_component.module.css";

class ChannelFromComponent extends Component {
  inputRefs = {
    channel: React.createRef(),
    group_id: React.createRef(),
    title: React.createRef(),
    currency: React.createRef(),
  };

  componentDidMount() {
    const { focusField } = this.props;

    if (focusField) {
      this.inputRefs?.[focusField]?.current?.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const { focusField } = this.props;

    if (focusField && (prevProps.focusField !== focusField)) {
      setTimeout(() => {
        this.inputRefs?.[focusField]?.current?.focus();
      }, 0);
    }
  }

  getGroupProperties = () => {
    const { properties, propertyOptions, form } = this.props;
    const propertyOptionsMap = propertyOptions.reduce((acc, el) => {
      acc[el.id] = el;
      return acc;
    }, {});
    const visiblePropertyIds = Object.values(properties).map((el) => el.id);
    const options = Object.values(properties).map((el) => ({
      key: el.id,
      title: el.title,
    }));
    const selectedOptions = form.values.properties
      .filter((id) => !(id in visiblePropertyIds))
      .filter((id) => propertyOptionsMap[id])
      .map((id) => {
        const el = propertyOptionsMap[id];
        return { key: el.id, title: el.title, disabled: true };
      });

    return selectedOptions.reduce((acc, selected) => {
      if (acc[selected.key]) {
        return acc;
      }

      acc[selected.key] = selected;
      return acc;
    }, options);
  };

  getChannelOptions = () => {
    const { availableChannels = [] } = this.props;
    return availableChannels.map((el) => ({
      value: el.code,
      representation: el.title,
    }));
  };

  getGroupOptions = () => {
    const { groups } = this.props;
    return groups.map((el) => ({ value: el.id, representation: el.title }));
  };

  getCurrencyOptions = () => {
    const nullValue = { value: null, representation: "Auto" };

    return [nullValue, ...currencyOptions()];
  };

  updateMinStayType = (settingsSchema, form, properties) => {
    if (settingsSchema.min_stay_type) {
      const minStayType = properties
        .filter((el) => form.values.properties.indexOf(el.id) !== -1)
        .reduce((acc, { min_stay_type }) => {
          if (acc === null) {
            return min_stay_type;
          }
          if (acc === min_stay_type) {
            return min_stay_type;
          }
          return "both";
        }, null);

      if (minStayType === "arrival" || minStayType === "through") {
        settingsSchema.min_stay_type = {
          default: minStayType === "arrival" ? "Arrival" : "Through",
          position: settingsSchema.min_stay_type.position,
          title: settingsSchema.min_stay_type.title,
          type: "hidden",
        };
      } else {
        settingsSchema.min_stay_type = {
          default: "Arrival",
          position: settingsSchema.min_stay_type.position,
          title: settingsSchema.min_stay_type.title,
          options: ["Arrival", "Through"],
          type: "switch",
        };
      }
    }

    return settingsSchema;
  };

  renderSettings = () => {
    const { form, mappingSchema, focusField, onConnectionChecked } = this.props;
    let settingsSchema = this.props.settingsSchema;

    if (!settingsSchema) {
      return null;
    }

    settingsSchema = this.updateMinStayType(settingsSchema, form, this.props.properties);

    return (
      <div className={componentStyles.connectionSettingsContainer}>
        <ConnectionSettings
          namespace="settings"
          form={form}
          settingsSchema={settingsSchema}
          mappingSchema={mappingSchema}
          focusField={focusField}
          onConnectionChecked={onConnectionChecked}
        />
      </div>
    );
  };

  renderOauthAirBNB = () => {
    const { form, selectedChannel } = this.props;

    return (
      <AirBnbAuthComponent form={form} selectedChannel={selectedChannel} />
    );
  };

  renderHotelbedsContracts = () => {
    const { form, connectionSettings, onMappingDetailsLoad } = this.props;

    return (
      <HotelbedsContractSelect
        form={form}
        connectionSettings={connectionSettings}
        onMappingDetailsLoad={onMappingDetailsLoad}
      />
    );
  };

  renderGoogleHotelARIStatus = () => {
    const { form, t } = this.props;

    return (
      <FormItem label={t("channels_page:form:channel_status")}>
        <div className={styles.placeholderContainer}>
          <Tag>
            {t(
              `channels_page:form:channel_statuses:${
                form.values.status || "pending"
              }`,
            )}
          </Tag>
        </div>
      </FormItem>
    );
  };

  renderVRBOAuth = () => {
    const { form, onMappingDetailsLoad } = this.props;

    return <VRBO form={form} onMappingDetailsLoad={onMappingDetailsLoad} />;
  };

  renderOauthRefresh = () => {
    const { t } = this.props;

    return (
      <div>
        <WarningOutlined className={styles.status__warningIcon} />
        &nbsp;
        {t("channels_page:connection_test:incorrect")}
      </div>
    );
  };

  renderOauthSuccess = () => {
    const { t } = this.props;

    return (
      <div>
        <CheckCircleOutlined className={settingsStyles.status__successIcon} />
        &nbsp;
        {t("channels_page:connection_test:success")}
      </div>
    );
  };

  renderExistingOauthStatus = () => {
    const { form } = this.props;

    const { settings } = form.values;
    const { token_invalid } = settings;

    if (token_invalid) {
      return this.renderOauthRefresh();
    }

    return this.renderOauthSuccess();
  };

  renderOauth = () => {
    const { t, selectedChannel, form, mappingSchema } = this.props;
    let { settingsSchema } = this.props;
    let authContent = null;

    settingsSchema = this.updateMinStayType(settingsSchema, form, this.props.properties);

    if (form.values.id) {
      authContent = this.renderExistingOauthStatus();
    }

    if (selectedChannel.code === channelCodes.AirBNB.code) {
      authContent = this.renderOauthAirBNB();

      // this param is handled in AirbnbAdditionalSettings component with own workflow
      const airbnbSettingsSchema = { ...settingsSchema };
      delete airbnbSettingsSchema.rate_plans_enabled;
      settingsSchema = airbnbSettingsSchema;
    }

    if (!authContent) {
      return null;
    }

    return (
      <div className={componentStyles.connectionSettingsContainer}>
        <legend>{t("channels_page:form:connection_settings")}</legend>
        <ConnectionSettingsForm
          settingsSchema={settingsSchema}
          mappingSchema={mappingSchema}
          form={form}
          namespace="settings"
          onChange={this.handleConnectionSettingsChange}
        />
        {authContent}
      </div>
    );
  };

  renderChannelAdditionalSettings = () => {
    const { selectedChannel } = this.props;

    const content = {
      [channelCodes.Hotelbeds.code]: this.renderHotelbedsContracts(),
      [channelCodes.Roibos.code]: this.renderHotelbedsContracts(),
      [channelCodes.GoogleHotelARI.code]: this.renderGoogleHotelARIStatus(),
      [channelCodes.VRBO.code]: this.renderVRBOAuth(),
      [channelCodes.BookingCom.code]: this.renderBookingComPricingSettings(),
    };

    return content[selectedChannel?.code];
  };

  renderBookingComPricingSettings = () => {
    const {
      channelId,
      mappingOptions,
      onChangeBookingComPricingMode,
      bookingComPricingChangeInProgress,
      t,
    } = this.props;

    const disabled = !mappingOptions || bookingComPricingChangeInProgress || !channelId;

    return (
      <FormItem label={t("channels_page:form:pricing_type")} extra={t("channels_page:form:pricing_type_extra")}>
        <div className={styles.placeholderContainer}>
          <Radio.Group
            value={mappingOptions?.pricing_type}
            onChange={onChangeBookingComPricingMode}
            buttonStyle="solid"
            disabled={disabled}
            style={{ width: "100%", textAlign: "center" }}
          >
            <Radio.Button style={{ width: "50%" }} value="Standard">{t("channels_page:form:standard_pricing")}</Radio.Button>
            <Radio.Button style={{ width: "50%" }} value="OBP">{t("channels_page:form:obp_pricing")}</Radio.Button>
          </Radio.Group>
          {bookingComPricingChangeInProgress && (
          <LoadingOutlined style={{
            position: "absolute",
            top: "13px",
            left: "50%",
            marginLeft: "-8px",
          }}
          />
          )}
        </div>

      </FormItem>
    );
  };

  handleConnectionSettingsChange = (value, field, form) => {
    form.setFieldValue(field.name, value);
  };

  renderPropertySelect = (isEmbeddedMode) => {
    const {
      t,
      isShowPropertySelect,
      isSinglePropertySelect,
      onPropertiesChange,
      activeProperty,
    } = this.props;
    const hasSinglePreselectedProperty = typeof (activeProperty) !== "undefined" && isEmbeddedMode;

    if (!isShowPropertySelect || hasSinglePreselectedProperty) {
      return null;
    }

    return isSinglePropertySelect ? (
      <FormSelect
        view="horizontal"
        label={t("channels_page:form:property_label")}
        placeholder={t("channels_page:form:property_placeholder")}
        name="properties"
        valueKey="key"
        labelKey="title"
        onChange={onPropertiesChange}
        options={this.getGroupProperties()}
      />
    ) : (
      <FormTableSelect
        title={t("channels_page:form:affected_properties_title")}
        label={t("channels_page:form:affected_properties")}
        name="properties"
        items={this.getGroupProperties()}
        onChange={onPropertiesChange}
      />
    );
  };

  renderChannelFields = () => {
    const {
      t,
      form,
      channelId,
      connectionSettings,
      selectedChannel,
      availableChannels,
      onChannelChange,
      onGroupChange,
      appMode,
      user,
    } = this.props;

    const { system_role: userRole } = user;
    const isAdmin = userRole === "admin";

    const isEmbeddedMode = appMode === APP_MODES.HEADLESS;
    const isCurrencyEditable = (!channelId && !connectionSettings?.currency) || isAdmin;

    return (
      <>
        {channelId && (
          <Form.Item
            data-cy="channel_id"
            {...horizontalFormItemLayout}
            label={t("general:id")}
          >
            {channelId}
            <CopyToClipboard text={channelId} />
          </Form.Item>
        )}
        {channelId ? (
          <Form.Item
            data-cy="channel_label"
            {...horizontalFormItemLayout}
            label={t("channels_page:form:channel_label")}
          >
            {selectedChannel.title}
          </Form.Item>
        ) : (
          <FormSelect
            inputRef={this.inputRefs.channel}
            view="horizontal"
            placeholder={t("channels_page:form:channel_placeholder")}
            label={t("channels_page:form:channel_label")}
            name="channel"
            options={this.getChannelOptions()}
            onChange={onChannelChange}
            disabled={availableChannels === null}
          />
        )}
        {!isEmbeddedMode && (
          <FormSelect
            inputRef={this.inputRefs.group_id}
            view="horizontal"
            placeholder={t("channels_page:form:group_placeholder")}
            label={t("channels_page:form:group_label")}
            name="group_id"
            onChange={onGroupChange}
            options={this.getGroupOptions()}
          />
        )}
        <FormInput
          inputRef={this.inputRefs.title}
          view="horizontal"
          placeholder={t("channels_page:form:title_placeholder")}
          label={t("channels_page:form:title_label")}
          name="title"
        />
        {this.renderPropertySelect(isEmbeddedMode)}
        <FormSelect
          inputRef={this.inputRefs.currency}
          view="horizontal"
          defaultValue={null}
          placeholder={t("channels_page:form:currency_placeholder")}
          label={t("channels_page:form:currency_label")}
          name="currency"
          options={this.getCurrencyOptions()}
          disabled={!isCurrencyEditable}
        />
        <BookingEngineLink form={form} />
      </>
    );
  };

  render() {
    const { credentialsSource } = this.props;

    return (
      <>
        {this.renderChannelFields()}
        {credentialsSource === "form" && this.renderSettings()}
        {credentialsSource === "oauth" && this.renderOauth()}
        {this.renderChannelAdditionalSettings()}
      </>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default withTranslation()(connect(mapStateToProps)(ChannelFromComponent));
