import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Empty, Timeline } from "antd";
import store from "store";

import ChannexDrawer from "drawers/channex_drawer";
import TaskDrawer from "drawers/task_drawer";

import DrawerFormWrapper from "components/drawer_form_wrapper";
import Loading from "components/loading";

import { formatToLocal } from "utils/dates";

import styles from "./state_changes_drawer.module.css";

const { StateChanges } = store;

export default function StateChangesDrawer(props) {
  const { visible, onClose, ratePlanId, roomTypeId, date, restriction, propertyId } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [changes, setChanges] = useState([]);
  const [taskDrawerIsVisible, setTaskDrawerIsVisible] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);

  useEffect(
    function loadStateChanges() {
      if (!roomTypeId && !ratePlanId) {
        return;
      }

      if (roomTypeId) {
        StateChanges.forRoom(
          propertyId,
          roomTypeId,
          date,
          restriction,
        ).then((data) => {
          setChanges(data);
          setLoading(false);
        });
      } else {
        StateChanges.forRate(
          propertyId,
          ratePlanId,
          date,
          restriction,
        ).then((data) => {
          setChanges(data);
          setLoading(false);
        });
      }
    },
    [propertyId, ratePlanId, roomTypeId, date, restriction],
  );

  const closeDrawer = () => {
    onClose();
    setTimeout(() => {
      setLoading(true);
      setChanges([]);
    }, 300);
  };

  const handleTaskDrawerClose = () => {
    setSelectedTaskId(null);
    setTaskDrawerIsVisible(false);
  };

  const handleTaskDrawerOpen = (taskId) => {
    return () => {
      setSelectedTaskId(taskId);
      setTaskDrawerIsVisible(true);
    };
  };

  const changesIsPresent = () => {
    return !loading && changes && changes.length > 0;
  };

  const changesIsEmpty = () => {
    return !loading && changes && changes.length === 0;
  };

  const renderLoading = () => {
    return (
      <Loading />
    );
  };

  const renderEmpty = () => {
    return (
      <DrawerFormWrapper>
        <Empty description={t("state_changes:empty_message")} />
      </DrawerFormWrapper>
    );
  };

  const renderChanges = () => {
    return (
      <DrawerFormWrapper>
        <Timeline>
          {
            changes.map(({ attributes }) => {
              const {
                taskId,
                task,
                insertedAt,
                value,
              } = attributes;

              const valueRepresentation = (typeof value === "boolean") ? value.toString() : value;

              return (
                <Timeline.Item key={taskId}>
                  <small>{formatToLocal(insertedAt, t("formats:iso_date_with_time"))}</small><br />
                  {t(`general:restrictions:${restriction}`)}: <strong>{valueRepresentation}</strong><br />
                  {t("state_changes:task")}: <Button className={styles.flatButton} type="link" onClick={handleTaskDrawerOpen(taskId)}>{task}</Button>
                </Timeline.Item>
              );
            })
          }
        </Timeline>
      </DrawerFormWrapper>
    );
  };

  return (
    <ChannexDrawer
      title={t("state_changes:header")}
      onClose={closeDrawer}
      visible={visible}
    >
      {() => (
        <>
          {loading && renderLoading()}
          {changesIsPresent() && renderChanges()}
          {changesIsEmpty() && renderEmpty()}
          <TaskDrawer
            visible={taskDrawerIsVisible}
            taskId={selectedTaskId}
            onClose={handleTaskDrawerClose}
          />
        </>
      )}
    </ChannexDrawer>
  );
}
