import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form } from "antd";
import store from "store";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import CancellationPolicyEditDrawer from "drawers/cancellation_policy_edit_drawer";
import TaxSetDrawerControlled from "drawers/tax_set_drawer_controlled";

import ErrorBoundary from "components/error_boundary";
import CopyToClipboard from "components/forms/buttons/copy_to_clipboard/copy_to_clipboard";
import SubmitButton from "components/forms/buttons/submit_button";
import GroupAdvancedSettings from "components/forms/groups/group_advanced_settings";
import GroupDerivedRate from "components/forms/groups/group_derived_rate";
import GroupPriceSettings from "components/forms/groups/group_price_settings";
import FormCheckbox from "components/forms/inputs/form_checkbox";
import FormSelectModifiable from "components/forms/inputs/form_select_modifiable";
import InputMealType from "components/forms/items/input_meal_type";
import InputProperty from "components/forms/items/input_property";
import InputRateMode from "components/forms/items/input_rate_mode";
import InputRoomType from "components/forms/items/input_room_type";
import InputTitle from "components/forms/items/input_title";
import Loading from "components/loading";

import convertToHashmap from "utils/convert_to_hashmap";
import showErrorMessage from "utils/show_error_message";

import withLogic from "./rate_form_logic";

import styles from "styles/form_in_drawer.module.css";

const { CancellationPolicies, RatePlans, RoomTypes, TaxSets, Properties } = store;

const EMPTY_MODEL = {
  property_id: undefined,
  currency: undefined,
  room_type_id: undefined,
  parent_rate_plan_id: undefined,
  cancellation_policy_id: undefined,
  meal_type: undefined,
  rate: 0,
  sell_mode: "per_room",
  rate_mode: "manual",
  is_derived: false,
  children_fee: 0,
  infant_fee: 0,
  increase_value: 0,
  decrease_value: 0,
  increase_mode: "$",
  decrease_mode: "$",
  inherit_rate: false,
  inherit_min_stay_arrival: false,
  inherit_min_stay_through: false,
  inherit_max_stay: false,
  inherit_closed_to_arrival: false,
  inherit_closed_to_departure: false,
  inherit_stop_sell: false,
  options: [],
  is_advanced_rate: false,
  ui_read_only: false,
  derived_option: {
    rate: [],
  },
};

const cloneSettingsFromParentRate = (state, updates) => {
  const parentRateOption = state && state.ratePlans ? state.ratePlans[updates.value.parent_rate_plan_id] : null;

  if (parentRateOption && parentRateOption.room_type_id === updates.value.room_type_id) {
    return RatePlans.find(parentRateOption.id).then((parentRatePlan) => {
      updates.value.sell_mode = parentRatePlan.sell_mode;
      updates.value.rate_mode = state.value.is_derived && parentRatePlan.rate_mode === "manual"
        ? "derived"
        : parentRatePlan.rate_mode;

      if (parentRatePlan.auto_rate_settings) {
        updates.value.increase_mode = parentRatePlan.auto_rate_settings.increase_mode;
        updates.value.decrease_mode = parentRatePlan.auto_rate_settings.decrease_mode;
        updates.value.increase_value = parentRatePlan.auto_rate_settings.increase_value;
        updates.value.decrease_value = parentRatePlan.auto_rate_settings.decrease_value;
      }

      updates.value.primary_occupancy = parentRateOption.occupancy;
      updates.value.occupancy = Math.max.apply(null, parentRatePlan.options.map((option) => option.occupancy));

      updates.value.options = new Array(updates.value.occupancy).fill(null).map((el, index) => {
        return {
          occupancy: index + 1,
          rate: 0,
          is_primary: index + 1 === updates.value.occupancy,
          derived_option: { rate: [] },
        };
      });

      return updates;
    });
  }

  return Promise.resolve(updates);
};

class RateForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    activeProperty: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  static mutators = {
    room_type_id: "onRoomTypeIdChange",
    property_id: "onPropertyIdChange",
    parent_rate_plan_id: "onParentRatePlanIdChange",
    is_derived: "onIsDerivedRateChange",
    inherit_rate: "onIsDerivedRateChange",
  };

  static contextType = ErrorBoundary.Context;

  state = {
    submitInProgress: false,
    isCancellationDrawerVisible: false,
    isTaxSetDrawerVisible: false,
    loading: true,
    errors: {},
    value: {},
    taxSetOptionsLoading: false,
    taxSetOptions: [],
    cancellationPoliciesOptionsLoading: false,
    cancellationPoliciesOptions: [],
    roomTypesAreLoading: false,
    isPropertyLoading: false,
  };

  componentDidMount() {
    const { propertiesOptions } = this.props;

    if (propertiesOptions) {
      this.prepareModel();
    }
  }

  componentDidUpdate(prevProps) {
    const { propertiesOptions } = this.props;

    if (propertiesOptions && propertiesOptions !== prevProps.propertiesOptions) {
      this.prepareModel();
    }
  }

  handleAsyncError = (error) => {
    const { handleError } = this.context;

    handleError(error);
  };

  loadOptions = (propertyId) => {
    this.loadTaxSetsOptions(propertyId);
    this.loadCancellationPoliciesOptions(propertyId);
  };

  loadCancellationPoliciesOptions = (propertyId) => {
    this.setState({ cancellationPoliciesOptionsLoading: true });

    return CancellationPolicies.options({ property_id: propertyId })
      .then((cancellationPoliciesOptions) => {
        this.setState({ cancellationPoliciesOptions, cancellationPoliciesOptionsLoading: false });
      })
      .catch(this.handleAsyncError);
  };

  loadTaxSetsOptions = (propertyId) => {
    this.setState({ taxSetOptionsLoading: true });

    return TaxSets.options({ property_id: propertyId })
      .then((taxSetOptions) => {
        this.setState({ taxSetOptions, taxSetOptionsLoading: false });
      })
      .catch(this.handleAsyncError);
  };

  prepareModel = () => {
    const { id } = this.props;

    if (id) {
      this.prepareExistedModel();
    } else {
      this.prepareNewModel();
    }
  };

  prepareNewModel = () => {
    this.loadRoomType()
      .then(this.setPropertyId)
      .then(this.loadProperty)
      .then(this.loadRoomTypes)
      .then(this.loadRatePlans)
      .then(this.generateDefaultValue)
      .then(this.initNewModel);
  };

  prepareExistedModel = () => {
    this.loadRatePlan()
      .then(this.setPropertyId)
      .then(this.loadRoomTypes)
      .then(this.loadRatePlans)
      .then(this.initExistedModel);
  };

  loadRoomType = () => {
    return new Promise((resolve) => {
      const { roomTypeId } = this.props;

      if (roomTypeId) {
        resolve(
          RoomTypes.find(roomTypeId)
            .then((roomType) => {
              return {
                room_type_id: roomTypeId,
                room_type: roomType,
              };
            })
            .catch(this.handleAsyncError),
        );
      } else {
        resolve({
          room_type_id: undefined,
          room_type: null,
        });
      }
    });
  };

  loadRatePlan = () => {
    return new Promise((resolve) => {
      const { id } = this.props;

      if (id) {
        resolve(
          RatePlans.find(id)
            .then((ratePlan) => {
              return {
                rate_plan: ratePlan,
              };
            })
            .catch(this.handleAsyncError),
        );
      } else {
        resolve({
          rate_plan: null,
        });
      }
    });
  };

  setPropertyId = (payload) => {
    return new Promise((resolve) => {
      let propertyId = this.props.activeProperty;

      if (payload.rate_plan) {
        propertyId = payload.rate_plan.property_id;
      } else if (payload.room_type) {
        propertyId = payload.room_type.property_id;
      }

      this.loadOptions(propertyId);

      resolve({ ...payload, property_id: propertyId });
    });
  };

  loadRoomTypes = (payload) => {
    return new Promise((resolve) => {
      if (payload.property_id) {
        resolve(
          RoomTypes.options({ property_id: payload.property_id })
            .then((room_types) => {
              return { ...payload, room_types };
            })
            .catch(this.handleAsyncError),
        );
      } else {
        resolve({ ...payload, room_types: [] });
      }
    });
  };

  loadProperty = (payload) => {
    this.setState({ isPropertyLoading: true });

    return Properties.find(payload.property_id)
      .then((property) => {
        this.setState({ isPropertyLoading: false });
        return { ...payload, property };
      })
      .catch(this.handleAsyncError);
  };

  loadRatePlans = (payload) => {
    return new Promise((resolve) => {
      if (payload.property_id) {
        resolve(
          RatePlans.options({ property_id: payload.property_id })
            .then((response) => {
              const rate_plans = response.reduce((acc, el) => {
                acc[el.id] = el;
                return acc;
              }, {});

              return {
                ...payload,
                rate_plans,
              };
            })
            .catch(this.handleAsyncError),
        );
      } else {
        resolve({ ...payload, rate_plans: [] });
      }
    });
  };

  initNewModel = (payload) => {
    const { value, room_type, room_types } = payload;
    const model = value;

    if (value.room_type_id) {
      this.updateModelWithRoomData(room_type, { roomTypes: room_types }, { value: model }, model)
        .then((updatedModel) => {
          this.setModelSettings(payload, updatedModel.value);
        })
        .catch(this.handleAsyncError);
      return;
    }

    this.setModelSettings(payload, model);
  };

  initExistedModel = (payload) => {
    const value = this.prepareValue(payload.rate_plan);

    this.setModelSettings(payload, value);
  };

  setModelSettings = (payload, model) => {
    this.setState({
      loading: false,
      roomTypes: payload.room_types,
      ratePlans: payload.rate_plans,
      value: model,
    });
  };

  generateDefaultValue = (payload) => {
    const { room_type_id, property_id, property } = payload;
    const { propertiesOptionsById } = this.props;

    return new Promise((resolve) => {
      const activeProperty = property_id ? propertiesOptionsById[property_id] : null;

      resolve({
        ...payload,
        value: {
          ...EMPTY_MODEL,
          property_id: activeProperty ? activeProperty.id : undefined,
          currency: activeProperty ? activeProperty.currency : undefined,
          min_stay_arrival: new Array(7).fill(1),
          min_stay_through: new Array(7).fill(1),
          max_stay: new Array(7).fill(0),
          closed_to_arrival: new Array(7).fill(false),
          closed_to_departure: new Array(7).fill(false),
          stop_sell: new Array(7).fill(false),
          room_type_id: room_type_id || undefined,
          tax_set_id: property.default_tax_set_id,
          cancellation_policy_id: property.default_cancellation_policy_id,
        },
      });
    });
  };

  generateOccupancyOptions = (value, occupancy) => {
    return new Array(occupancy).fill(null).map((el, index) => {
      return {
        occupancy: index + 1,
        rate: 0,
        derived_option: { rate: [] },
      };
    });
  };

  prepareValue = (model) => {
    const primaryRate = model.options.find((option) => option.is_primary);
    const isDerived = Boolean(model.parent_rate_plan_id);
    const defaultRateValue = isDerived ? [...primaryRate.derived_option.rate] : [];

    model.primary_occupancy = model.occupancy || primaryRate.occupancy;
    model.is_derived = isDerived;

    if (!model.derived_option) {
      model.derived_option = { rate: defaultRateValue };
    }
    if (!model.derived_option.rate) {
      model.derived_option.rate = defaultRateValue;
    }
    if (model.auto_rate_settings) {
      model.increase_mode = model.auto_rate_settings.increase_mode || "$";
      model.decrease_mode = model.auto_rate_settings.decrease_mode || "$";
      model.increase_value = model.auto_rate_settings.increase_value || 0;
      model.decrease_value = model.auto_rate_settings.decrease_value || 0;
    } else {
      model.increase_mode = "$";
      model.decrease_mode = "$";
      model.increase_value = 0;
      model.decrease_value = 0;
    }

    if (model.options) {
      model.options = model.options.map((el) => {
        return {
          ...el,
          derived_option:
            el.derived_option && el.derived_option.rate ? el.derived_option : { rate: [] },
        };
      });
    }

    model.rate = primaryRate.rate;

    return model;
  };

  onRoomTypeIdChange = (props, state, updates, model, value) => {
    return RoomTypes.find(value).then((roomType) => {
      const newModel = this.updateModelWithRoomData(roomType, state, updates, model);

      return newModel;
    });
  };

  updateModelWithRoomData = (roomType, state, updates, model) => {
    const { occ_adults, default_occupancy } = roomType;

    updates.value.options = this.generateOccupancyOptions(model, occ_adults);
    updates.value.occupancy = occ_adults;
    updates.value.primary_occupancy = default_occupancy;

    updates = cloneSettingsFromParentRate(state, updates);

    return updates;
  };

  onPropertyIdChange = (props, state, updates, model, value) => {
    const { propertiesOptionsById } = props;
    const newProperty = propertiesOptionsById[value];

    updates.value.currency = newProperty.currency || updates.value.currency;
    updates.value.parent_rate_plan_id = undefined;
    updates.value.room_type_id = undefined;

    this.setState({ roomTypesAreLoading: true });
    this.loadRoomTypes({ property_id: value })
      .then(this.loadRatePlans)
      .then((payload) => {
        this.setState({
          roomTypesAreLoading: false,
          roomTypes: payload.room_types,
          ratePlans: payload.rate_plans,
        });
      });

    this.loadProperty({ property_id: value }).then(({ property }) => {
      const { default_tax_set_id, default_cancellation_policy_id } = property;

      this.setState(({ value: oldValue }) => ({
        value: {
          ...oldValue,
          tax_set_id: default_tax_set_id,
          cancellation_policy_id: default_cancellation_policy_id,
        },
      }));
    });

    return updates;
  };

  onParentRatePlanIdChange = (_props, state, updates, model, value) => {
    if (value) {
      updates.value.inherit_rate = true;
      updates.value.inherit_closed_to_arrival = true;
      updates.value.inherit_closed_to_departure = true;
      updates.value.inherit_max_stay = true;
      updates.value.inherit_min_stay_arrival = true;
      updates.value.inherit_min_stay_through = true;
      updates.value.inherit_stop_sell = true;

      if (model.rate_mode === "manual") {
        updates.value.rate_mode = "derived";
      }
    }

    updates = cloneSettingsFromParentRate(state, updates);

    return updates;
  };

  onIsDerivedRateChange = (_props, _state, updates, model, value) => {
    if (value === true && model.parent_rate_plan_id && model.rate_mode === "manual") {
      updates.value.rate_mode = "derived";
    }

    return updates;
  };

  onSuccess = () => {
    const { onClose } = this.props;

    onClose();
  };

  onError = (response) => {
    const { t } = this.props;

    this.setState({ submitInProgress: false });

    if (response && response.errors && response.errors.code) {
      if (response.errors.code === "validation_error") {
        this.setState({
          errors: response.errors.details,
        });
      } else {
        showErrorMessage(t(`general:${response.errors.code}`));
      }
    } else {
      showErrorMessage(t("general:undefined_error"));
    }
  };

  onChange = (field, val) => {
    const { value } = this.state;

    if (field === "rate" && value.id && value.sell_mode === "per_room") {
      value.options[0].rate = val;
    }

    let updates = {
      value: {
        ...value,
        [field]: val,
      },
    };

    if (RateForm.mutators[field] && typeof this[RateForm.mutators[field]] === "function") {
      updates = this[RateForm.mutators[field]](this.props, this.state, updates, value, val);
    }

    if (updates instanceof Promise) {
      updates
        .then((result) => {
          this.setState(result);
        })
        .catch(this.handleAsyncError);
    } else {
      this.setState(updates);
    }
  };

  onChangeCheckbox = (value, field) => {
    this.onChange(field, value);
  };

  onChangeNamelessInput = (field) => (val) => {
    this.onChange(field, val);
  };

  onSubmit = () => {
    const { onSubmit } = this.props;

    this.setState({ submitInProgress: true });

    const { value } = this.state;

    onSubmit(value, this.onSuccess.bind(this), this.onError.bind(this));
  };

  handleCancellationDrawerOpen = () => {
    this.setState({ isCancellationDrawerVisible: true });
  };

  handleCancellationDrawerClose = () => {
    this.setState({ isCancellationDrawerVisible: false });
  };

  handleTaxSetVisibilityToggle = () => {
    this.setState(({ isTaxSetDrawerVisible }) => ({
      isTaxSetDrawerVisible: !isTaxSetDrawerVisible,
    }));
  };

  getCancellationPoliciesOptions = () => {
    const { cancellationPoliciesOptions } = this.state;

    return cancellationPoliciesOptions.map(({ id, title }) => ({
      value: id,
      representation: title,
    }));
  };

  handlePolicyCreate = (values) => {
    const { value } = this.state;

    this.loadCancellationPoliciesOptions(value.property_id).then(() => {
      this.onChange("cancellation_policy_id", values.data.id);
    });
  };

  getTaxSetsOptions = () => {
    const { taxSetOptions } = this.state;

    return taxSetOptions.map(({ id, title }) => ({
      value: id,
      representation: title,
    }));
  };

  handleTaxSetCreate = (values) => {
    const { value } = this.state;

    this.loadTaxSetsOptions(value.property_id).then(() => {
      this.onChange("tax_set_id", values.id);
    });
  };

  render() {
    const {
      submitInProgress,
      value,
      errors,
      loading,
      roomTypes,
      roomTypesAreLoading,
      ratePlans,
      isCancellationDrawerVisible,
      isTaxSetDrawerVisible,
      isPropertyLoading,
      cancellationPoliciesOptionsLoading,
      taxSetOptionsLoading,
    } = this.state;
    const { t, propertiesOptions, isEditable = true } = this.props;
    const cancellation_policy_id = value.cancellation_policy_id || undefined; // select won't show placeholder if it's value === null which comes from backend
    const tax_set_id = value.tax_set_id || undefined;

    if (loading) {
      return <Loading />;
    }

    return (
      <Form onFinish={this.onSubmit}>
        {value.id && (
          <Form.Item
            data-cy="rate_id_container"
            labelCol={horizontalFormItemLayout.labelCol}
            wrapperCol={horizontalFormItemLayout.wrapperCol}
            label={t("general:id")}
          >
            {value.id}
            <CopyToClipboard text={value.id} />
          </Form.Item>
        )}
        <InputTitle
          t={t}
          errors={errors}
          disabled={!isEditable}
          model={value}
          onChange={this.onChange}
        />
        <InputProperty
          t={t}
          errors={errors}
          disabled={!isEditable}
          model={value}
          loading={isPropertyLoading}
          properties={propertiesOptions}
          onChange={this.onChange}
        />
        <InputRoomType
          t={t}
          errors={errors}
          disabled={!isEditable}
          model={value}
          roomTypes={roomTypes.filter((el) => el.property_id === value.property_id)}
          loading={roomTypesAreLoading}
          onChange={this.onChange}
        />
        <InputRateMode
          t={t}
          errors={errors}
          disabled={!isEditable}
          model={value}
          onChange={this.onChange}
        />
        <GroupDerivedRate
          t={t}
          errors={errors}
          disabled={!isEditable}
          model={value}
          roomTypes={roomTypes}
          ratePlans={ratePlans}
          onChange={this.onChange}
        />
        <GroupAdvancedSettings
          errors={errors}
          disabled={!isEditable}
          model={value}
          onChange={this.onChange}
        />
        <GroupPriceSettings
          errors={errors}
          disabled={!isEditable}
          model={value}
          onChange={this.onChange}
        />

        <legend>{t("rates_page:form:additional_information_legend")}</legend>
        <InputMealType
          errors={errors}
          disabled={!isEditable}
          model={value}
          onChange={this.onChange}
        />
        <FormSelectModifiable
          name="cancellation_policy_id"
          label={t("rates_page:form:cancellation_policy_label")}
          placeholder={t("rates_page:form:cancellation_policy_placeholder")}
          value={cancellation_policy_id}
          disabled={!isEditable}
          loading={cancellationPoliciesOptionsLoading}
          options={this.getCancellationPoliciesOptions()}
          errors={errors ? errors.cancellation_policy_id : null}
          onChange={this.onChangeNamelessInput("cancellation_policy_id")}
          onNew={this.handleCancellationDrawerOpen}
        />
        <CancellationPolicyEditDrawer
          propertyId={value.property_id}
          visible={isCancellationDrawerVisible}
          currency={value.currency}
          onClose={this.handleCancellationDrawerClose}
          onCreate={this.handlePolicyCreate}
        />

        <FormSelectModifiable
          name="tax_set_id"
          label={t("rates_page:form:tax_set_label")}
          placeholder={t("rates_page:form:tax_set_placeholder")}
          value={tax_set_id}
          disabled={!isEditable}
          loading={taxSetOptionsLoading}
          options={this.getTaxSetsOptions()}
          errors={errors ? errors.tax_set_id : null}
          onChange={this.onChangeNamelessInput("tax_set_id")}
          onNew={this.handleTaxSetVisibilityToggle}
        />
        <TaxSetDrawerControlled
          propertyId={value.property_id}
          visible={isTaxSetDrawerVisible}
          onClose={this.handleTaxSetVisibilityToggle}
          onCreate={this.handleTaxSetCreate}
        />

        <FormCheckbox
          view="horizontal"
          name="ui_read_only"
          label={t("rates_page:form:ui_read_only")}
          onChange={this.onChangeCheckbox}
          defaultValue={value.ui_read_only}
        />

        {isEditable && (
          <div className={styles.actions}>
            <SubmitButton loading={submitInProgress}>{t("rates_page:submit_button")}</SubmitButton>
          </div>
        )}
      </Form>
    );
  }
}

const mapStateToProps = ({ properties, session }) => {
  const activeProperty = session.activeProperty || undefined;
  const propertiesOptions = properties.options;

  return {
    propertiesOptions,
    propertiesOptionsById: convertToHashmap(propertiesOptions),
    activeProperty,
  };
};

export default withTranslation()(connect(mapStateToProps)(withLogic(RateForm)));
