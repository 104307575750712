import attributesExtractor from "../utils/attributes_extractor";
import { convertToCamelCase, convertToSnakeCase } from "../utils/case_converter";

let transport;

const ENDPOINT = "admin/applications";

export default class AdminUsers {
  constructor(container) {
    transport = container.transport;
  }

  list() {
    return transport
      .send("GET", ENDPOINT)
      .then(convertToCamelCase)
      .then(({ data }) => attributesExtractor(data));
  }

  listUsers(id) {
    return transport
      .send("GET", `${ENDPOINT}/${id}/users`)
      .then(convertToCamelCase);
  }

  revokeUserAccess({ applicationId, userId }) {
    const path = `${ENDPOINT}/${applicationId}/remove_access`;
    const body = convertToSnakeCase({ userId });

    return transport
      .send("POST", path, body)
      .then(convertToCamelCase);
  }

  grantUserAccess({ applicationId, userId }) {
    const path = `${ENDPOINT}/${applicationId}/grant_access`;
    const body = convertToSnakeCase({ userId });

    return transport
      .send("POST", path, body)
      .then(convertToCamelCase);
  }
}
