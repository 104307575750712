import React from "react";
import { useTranslation } from "react-i18next";
import { LinkOutlined, UserOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

import styles from "./title.module.css";

export default function RateTitle({ className, rate = {}, type, showReadOnly }) {
  const { t } = useTranslation();

  const isRateOccupancyPresent = rate.occupancy !== null && rate.occupancy !== undefined;
  const isReadOnly = rate.readonly;

  return (
    <span className={className}>
      <span data-cy="rate_title" className={styles.title}>
        {rate.title}
      </span>
      {isRateOccupancyPresent && (
        <span className={styles.occupancy} data-cy={type ? `${type}_occupancy` : "occupancy"}>
          <UserOutlined />
          {rate.occupancy}
        </span>
      )}
      {(showReadOnly && isReadOnly) && (
        <Tooltip title={t("rate_plans:read_only")}>
          &nbsp;
          <LinkOutlined />
        </Tooltip>
      )}
    </span>
  );
}
