import React, { Component } from "react";
import store from "store";

import withRouter from "routing/with_router";

import EventEmitter from "utils/event_emitter";

const { RatePlans } = store;

const getOptions = (value) => {
  let output = null;

  if (value.options) {
    if (value.rate_mode === "auto") {
      output = value.options
        .filter((el) => el.occupancy === value.primary_occupancy)
        .map((el) => {
          return {
            ...el,
            rate: value.rate,
            is_primary: true,
          };
        });
    } else {
      output = value.options.map((el) => {
        const result = {
          ...el,
          rate: el.occupancy === value.primary_occupancy ? value.rate : el.rate,
          is_primary: el.occupancy === value.primary_occupancy,
        };

        if (value.sell_mode === "per_person" && value.rate_mode === "manual") {
          result.rate = el.rate;
        }

        return result;
      });
    }
  }

  return output;
};

const getRate = (value) => {
  let output = null;
  if (value.sell_mode === "per_person" && value.rate_mode === "manual" && value.options) {
    const option = value.options.filter((el) => el.occupancy === value.primary_occupancy)[0];
    output = option.rate;
  } else {
    output = value.rate ? value.rate : null;
  }

  return output;
};

const prepareValue = (value) => {
  let output = {
    property_id: value.property_id,
    room_type_id: value.room_type_id,
    rate_category_id: value.rate_category_id,
    rate: getRate(value),
    options: getOptions(value),
  };

  output = Object.keys(output).reduce((acc, el) => {
    acc[el] = output[el] === "" ? null : output[el];
    return acc;
  }, {});

  return output;
};

const withLogic = (FormComponent) => {
  class HOC extends Component {
    constructor(props) {
      super(props);

      this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = (value, success, error) => {
      const method = value.id ? RatePlans.update : RatePlans.create;

      method(prepareValue(value)).then(
        (response) => {
          if (success && typeof success === "function") {
            EventEmitter.trigger("rate_plan:created", response.data.attributes);
            success(response);
          }
        },
        (response) => {
          if (error && typeof error === "function") {
            error(response);
          }
        },
      );
    };

    render() {
      return <FormComponent {...this.props} onSubmit={this.onSubmit} />;
    }
  }

  return withRouter(HOC);
};

export default withLogic;
