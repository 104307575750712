import React from "react";
import { useSelector } from "react-redux";
import { getActiveFeatureFlags } from "store/storage/selectors/session_selector";

export default function Feature({ name, children, ...rest }) {
  const activeFeatureFlags = useSelector(getActiveFeatureFlags);

  const featureEnabled = activeFeatureFlags[name];

  return featureEnabled ? React.cloneElement(children, rest) : null;
}
