import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Form } from "antd";
import store from "store";
import { v4 as uuidv4 } from "uuid";

import { horizontalFormItemLayout } from "config/constants/layouts/form";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import EventEmitter from "utils/event_emitter";
import showErrorMessage from "utils/show_error_message";
import useBoolState from "utils/use_bool_state";

import AirBnbAuthButton from "./air_bnb_auth_button";
import AirBnbAuthLink from "./air_bnb_auth_link";
import AirBnbLoadingCaption from "./air_bnb_loading_caption";

import styles from "./air_bnb.module.css";

const { ws } = store;

const AIRBNB_URL = "https://www.airbnb.com/oauth2/auth";

const getState = ({ values }, requestID) => {
  const defaultState = {
    request_id: requestID,
  };

  if (values.id) {
    return { ...defaultState, channel_id: values.id, group_id: values.group_id };
  }

  const stateSettings = {
    min_stay_type: values.settings.min_stay_type,
  };

  return {
    ...defaultState,
    group_id: values.group_id,
    properties: values.properties,
    channel: values.channel,
    title: values.title,
    settings: stateSettings,
  };
};

export default function AirBNB({ form, selectedChannel }) {
  const [requestState, setRequestState] = useState(null);
  const [requestID, setRequestID] = useState(null);
  const [authUrl, setAuthUrl] = useState(null);
  const [requestScope, setRequestScope] = useState(null);
  const [isLoading, setIsLoading, setIsNotLoading] = useBoolState(false);
  const { settings, properties } = form.values;
  const { token_invalid: isTokenInvalid } = settings;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const groupID = requestState?.group_id;

  const { userAppRoutes } = useRouting();

  const handleConnectionRequestSuccess = useCallback(
    (payload) => {
      const isTestEnv = import.meta.env.BASE_URL?.includes("cypress");

      if (!isTestEnv && (payload.request_id !== requestID)) {
        return;
      }

      setIsNotLoading();

      const redirectRoute = pathBuilder(userAppRoutes.channels.edit, {
        channelId: payload.channel_id,
      });

      if (window.location.pathname === redirectRoute) {
        window.location.reload();
      } else {
        EventEmitter.trigger("channels:updated");
        navigate(redirectRoute);
      }
    },
    [requestID, navigate, userAppRoutes, setIsNotLoading],
  );

  const handleConnectionRequestFail = useCallback(
    (payload) => {
      if (payload.request_id !== requestID) {
        return;
      }

      setIsNotLoading();

      showErrorMessage(t(`channel_events_page:errors:${payload.error}`));
    },
    [requestID, setIsNotLoading, t],
  );

  const handleAuthClick = useCallback(() => {
    window.open(authUrl, "_blank");
    setIsLoading();
  }, [authUrl, setIsLoading]);

  const handleConnectionRequestCancel = useCallback(() => setIsNotLoading(), [setIsNotLoading]);

  useEffect(() => {
    setRequestID(uuidv4());
  }, []);

  useEffect(() => {
    if (!groupID) {
      return;
    }

    const airBnbChannel = ws.socket.channel(`group:${groupID}`, {});
    airBnbChannel.join();

    airBnbChannel.on("channel_created", handleConnectionRequestSuccess);
    airBnbChannel.on("channel_not_created", handleConnectionRequestFail);

    return () => {
      airBnbChannel.leave();
    };
  }, [groupID, handleConnectionRequestSuccess, handleConnectionRequestFail]);

  useEffect(
    function updateAuthUrl() {
      const { payload } = selectedChannel;
      const newRequestState = getState(form, requestID);

      const airbnbOauthParams = {
        client_id: payload.client_id,
        redirect_uri: payload.redirect_uri,
        scope: payload.scope,
        state: JSON.stringify(newRequestState),
      };

      const airbnbQueryParams = new URLSearchParams(airbnbOauthParams).toString();
      const airbnbOauthUrl = `${AIRBNB_URL}?${airbnbQueryParams}`;

      setRequestState(newRequestState);
      setAuthUrl(airbnbOauthUrl);
      setRequestScope(payload.scope.split(",").sort().join(" "));
    },
    [form, selectedChannel, requestID],
  );

  const currentScope = (settings.scope || "").split(" ").sort().join(" ");
  const oauthUpdateRequired = requestScope !== currentScope;
  const shouldShowAuthButton = typeof settings.tokens === "undefined" || oauthUpdateRequired || isTokenInvalid;
  const isAuthButtonDisabled = !properties.length || isLoading;
  const isNewChannel = settings.tokens === undefined;
  let authButtonLabel;
  if (isNewChannel) {
    authButtonLabel = t("channels_page:connection_test:create_token");
  } else if (isTokenInvalid) {
    authButtonLabel = t("channels_page:connection_test:invalid_token");
  } else {
    authButtonLabel = t("channels_page:connection_test:update_token");
  }

  return (
    <>
      <Form.Item label="Host ID:" {...horizontalFormItemLayout}>
        <div>{settings?.tokens?.user_id || "N/A"}</div>
      </Form.Item>
      {shouldShowAuthButton ? (
        <Form.Item label={authButtonLabel} {...horizontalFormItemLayout}>
          <div className={styles.airbnbContainer}>
            <AirBnbAuthButton onClick={handleAuthClick} disabled={isAuthButtonDisabled} />
            <AirBnbAuthLink authUrl={authUrl} visible={!isAuthButtonDisabled} />
            {isLoading && <AirBnbLoadingCaption onCancel={handleConnectionRequestCancel} />}
          </div>
        </Form.Item>
      ) : null}
    </>
  );
}
