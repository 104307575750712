import React from "react";
import { Controller } from "react-hook-form";
import { Col, Row } from "antd";

import FormSelectRaw from "components/forms/inputs/form_select";

export default function FormSelect({ name, control, grouped, ...inputProps }) {
  if (grouped) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormSelectRaw
            grouped={grouped}
            {...inputProps}
            {...field}
          />
        )}
      />
    );
  }

  return (
    <Row>
      <Col span={24}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <FormSelectRaw
              grouped={grouped}
              {...inputProps}
              {...field}
            />
          )}
        />
      </Col>
    </Row>
  );
}
