import { convertToCamelCase } from "store/utils/case_converter";

import attributesExtractor from "../utils/attributes_extractor";

let transport;

const ENDPOINT = "billing_accounts";

export default class BillingAccounts {
  constructor(container) {
    transport = container.transport;
  }

  info() {
    return transport
      .send("GET", ENDPOINT)
      .then(convertToCamelCase)
      .then(({ data }) => {
        return attributesExtractor(data);
      });
  }

  config() {
    return transport.send("GET", `${ENDPOINT}/config`).then(({ data }) => {
      return attributesExtractor(data);
    });
  }
}
