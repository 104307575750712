import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import store from "store";

import CancellationPolicyEditDrawer from "drawers/cancellation_policy_edit_drawer";

import ErrorBoundary from "components/error_boundary";
import NoDataPlaceholder from "components/no_data_placeholder";

import alphabetSort from "utils/alphabet_sort";
import showErrorMessageFromResponse from "utils/show_error_message_from_response";
import useBoolState from "utils/use_bool_state";

import PolicyActions from "../tax_table/tax_actions";

import styles from "./policy_view.module.css";

const { CancellationPolicies } = store;

const ACTION_COLUMNS_WIDTH = 200;
const TABLE_ROW_KEY = "id";

export default function CancellationPolicy({ property, handleCancellationPolicyChange }) {
  const { t } = useTranslation();
  const { handleError } = useContext(ErrorBoundary.Context);
  const [isLoading, setLoading, setLoadingComplete] = useBoolState(false);
  const [activePolicyId, setActivePolicyId] = useState(null);
  const [policyOptions, setPoliciesOptions] = useState([]);
  const [
    isCreateCancellationPolicyDrawerOpen,
    setCreateHotelPolicyDrawerOpen,
    setCreateHotelPolicyDrawerClosed,
  ] = useBoolState(false);

  const [
    isEditCancellationPolicyDrawerOpen,
    setEditHotelPolicyDrawerOpen,
    setEditCancellationPolicyDrawerClosed,
  ] = useBoolState(false);

  const { id, currency, default_cancellation_policy_id: defaultCancellationPolicyId } = property;

  const loadPoliciesOptions = useCallback(async () => {
    try {
      setLoading();

      const data = await CancellationPolicies.options({ property_id: id });

      setPoliciesOptions(data);
      setLoadingComplete();
    } catch (error) {
      handleError(error);
    }
  }, [id, handleError, setLoading, setLoadingComplete]);

  const handleDelete = useCallback(
    async (policyId) => {
      try {
        await CancellationPolicies.remove({ id: policyId });

        const updatedPolicies = policyOptions.filter((policy) => policy.id !== policyId);

        setPoliciesOptions(updatedPolicies);
      } catch (error) {
        showErrorMessageFromResponse(error);
      }
    },
    [policyOptions],
  );

  const handleEdit = useCallback(
    (policyId) => {
      setActivePolicyId(policyId);
      setEditHotelPolicyDrawerOpen();
    },
    [setEditHotelPolicyDrawerOpen],
  );

  const handleSelect = useCallback(
    (policyId) => {
      return handleCancellationPolicyChange(policyId);
    },
    [handleCancellationPolicyChange],
  );

  const renderPolicyActions = useCallback(
    (_text, policy) => (
      <PolicyActions
        isDefault={defaultCancellationPolicyId === policy.id}
        id={policy.id}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onSelect={handleSelect}
        selectable
      />
    ),
    [defaultCancellationPolicyId, handleEdit, handleSelect, handleDelete],
  );

  const onEditCancellationPolicy = useCallback(() => {
    loadPoliciesOptions();
  }, [loadPoliciesOptions]);

  const onCreateCancellationPolicy = useCallback(
    ({ data }) => {
      if (!defaultCancellationPolicyId) {
        handleCancellationPolicyChange(data.id);
      }

      loadPoliciesOptions();
    },
    [handleCancellationPolicyChange, defaultCancellationPolicyId, loadPoliciesOptions],
  );

  useEffect(
    function loadPoliciesData() {
      loadPoliciesOptions();
    },
    [loadPoliciesOptions],
  );

  const placeholder = useMemo(() => {
    if (isLoading || !policyOptions || policyOptions.length) {
      return null;
    }
    return (
      <NoDataPlaceholder
        type="button"
        onClick={setCreateHotelPolicyDrawerOpen}
        emptyMessage={t("properties_page:policies:cancellation_policy:empty_message")}
        createMessageText={t("general:create_first_before")}
        createMessageActionText={t("general:create_first_link")}
      />
    );
  }, [t, isLoading, policyOptions, setCreateHotelPolicyDrawerOpen]);

  const columns = useMemo(() => {
    return [
      {
        title: t("general:hotel"),
        key: "title",
        dataIndex: "title",
        sorter: alphabetSort("title"),
        ellipsis: true,
      },
      {
        title: t("general:actions"),
        key: "actions",
        align: "right",
        width: ACTION_COLUMNS_WIDTH,
        render: renderPolicyActions,
      },
    ];
  }, [t, renderPolicyActions]);

  const sortedOptions = useMemo(() => {
    return [...policyOptions].sort((a) => {
      if (a.id === defaultCancellationPolicyId) {
        return -1;
      }
      return 0;
    });
  }, [policyOptions, defaultCancellationPolicyId]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>{t("properties_page:policies:cancellation_policy_title")}</p>
        <Button
          data-cy="create_cancellation_policy_button"
          icon={<PlusCircleOutlined />}
          type="link"
          onClick={setCreateHotelPolicyDrawerOpen}
        >
          {t("general:action:create")}
        </Button>
      </div>

      <div className={styles.tableContainer}>
        {policyOptions?.length ? (
          <Table
            loading={isLoading}
            rowClassName={styles.tableRow}
            pagination={false}
            showHeader={false}
            dataSource={sortedOptions}
            columns={columns}
            rowKey={TABLE_ROW_KEY}
          />
        ) : (
          placeholder
        )}
      </div>

      <CancellationPolicyEditDrawer
        id={activePolicyId}
        currency={currency}
        propertyId={id}
        visible={isEditCancellationPolicyDrawerOpen}
        onClose={setEditCancellationPolicyDrawerClosed}
        onCreate={onEditCancellationPolicy}
      />
      <CancellationPolicyEditDrawer
        id={activePolicyId}
        currency={currency}
        propertyId={id}
        visible={isCreateCancellationPolicyDrawerOpen}
        onClose={setCreateHotelPolicyDrawerClosed}
        onCreate={onCreateCancellationPolicy}
      />
    </div>
  );
}
