import React from "react";
import { useTranslation } from "react-i18next";
import { Cascader } from "antd";

import ChannexPopover from "components/channex_popover";
import RemoveButton from "components/forms/buttons/remove";

import MappingComponentDisplay from "./mapping_component_display";

import styles from "./mapping_popover.module.css";

const getRateIdsPath = (rate) => {
  if (!rate) {
    return null;
  }

  return [rate.property_id, rate.room_type_id, rate.id];
};

export default function MappingComponent(props) {
  const { t } = useTranslation();
  const {
    ratesTree,
    mappingPopupData,
    deleteEnabled = true,
    handleRateChange,
    handleApplyMapping,
    handleClosePopup,
    handlePopupDeleteClick,
  } = props;
  const { rate } = mappingPopupData;
  const popoverAdditionalButton = deleteEnabled && rate && <RemoveButton onClick={handlePopupDeleteClick} />;
  const ratePath = getRateIdsPath(rate);

  return (
    <ChannexPopover.Content
      title={t("mapping:map_rate_title")}
      additionalButtons={popoverAdditionalButton}
      disabled={!rate}
      onSave={handleApplyMapping}
      onCancel={handleClosePopup}
    >
      <Cascader
        className={styles.rateSelector}
        options={ratesTree}
        placeholder="Please select rate"
        allowClear={false}
        autoFocus
        fieldNames={{
          label: "label",
          value: "id",
          children: "children",
        }}
        value={ratePath}
        displayRender={MappingComponentDisplay}
        onChange={handleRateChange}
      />
    </ChannexPopover.Content>
  );
}
