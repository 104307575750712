import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { notification } from "antd";
import _ from "lodash";
import store from "store";

import RESTRICTIONS from "config/constants/inventory/restrictions";

import InventoryBulkUpdate from "components/inventory/inventory_bulk_update";
import InventoryErrorModal from "components/inventory/inventory_error_modal";
import InventoryFilters from "components/inventory/inventory_filters/inventory_filters";
import InventoryTable from "components/inventory/inventory_table/inventory_table";
import InventoryValueOverrideModal from "components/inventory_value_override_modal";
import Loading from "components/loading";
import MobileInventory from "components/mobile/inventory/mobile_inventory";
import * as Page from "components/page";

import withMediaQuery from "hooks/use_media_query/hoc";

import {
  addDays,
  dayjs,
  daysBetween,
  diffDays,
  formatDate,
  isBefore,
  isBetween,
  nowWithOffset,
  safeParseDate,
  startOfDay,
} from "utils/dates";
import EventEmitter from "utils/event_emitter";
import extractPerPersonRates from "utils/extract_per_person_rates";
import getArrayDays from "utils/get_array_days";
import getObjectKeyByOrderNumber from "utils/get_key_object_by_order_number";
import getSortedArray from "utils/get_sorted_array";
import titledIds from "utils/titled_ids";
import validateRestriction from "utils/validate_restriction";

import { withAvailabilityRuleOptions } from "../../data/use_availability_rule_options/hoc";
import { withTimezones } from "../../hooks/use_timezones/hoc";
import { pathBuilder } from "../../routing";
import withRouter from "../../routing/with_router";

import ErrorImage from "static/too-many-properties.svg";

const { ARI, RatePlans, RoomTypes, Properties, Channels, subscribe } = store;

const INVENTORY_TIME_OFFSET = [-2, "hours"];

const MOBILE_MAX_WIDTH = 749;
const MOBILE_DAYS_COUNT = 5;
const DEFAULT_DAYS_COUNT = 14;
const ANIMATION_DURATION_IN_MS = 300;
const DEBOUNCE_TIME = 1000;
const DEBOUNCE_LOAD_RESTRICTIONS_TIME = 300;
const booleanRestrictions = [
  "stop_sell",
  "stop_sell_manual",
  "closed_to_arrival",
  "closed_to_departure",
];
const VISIBLE_RESTRICTIONS = [
  ...RESTRICTIONS,
  "stop_sell_manual",
  "max_availability",
  "availability_offset",
];

const getParamsFromURL = () => {
  const queryString = window.location.search;
  const names = {
    rs: "selectedRestrictions",
    rt: "selectedRooms",
    rp: "selectedRates",
    date: "date",
  };

  return queryString
    .substring(1)
    .split("&")
    .filter((el) => el.indexOf("=") !== -1)
    .map((el) => {
      const [key, values] = el.split("=");
      return [key, values.split(",")];
    })
    .reduce((acc, [key, values]) => {
      acc[names[key]] = values;
      return acc;
    }, {});
};

const extractRateOptions = (entities) => {
  const records = [];
  const options = [];

  entities.forEach((ratePlan) => {
    ratePlan.options
      .filter((option) => option.is_primary === false)
      .forEach((option) => {
        options.push({
          id: option.id,
          property_id: ratePlan.property_id,
          parent_rate_plan_id: ratePlan.id,
          is_option: true,
          occupancy: option.occupancy,
          title: ratePlan.title,
          options: [{ ...option, is_primary: true }],
          room_type_id: ratePlan.room_type_id,
          inherit_availability_offset: option.inherit_availability_offset,
          inherit_closed_to_arrival: option.inherit_closed_to_arrival,
          inherit_closed_to_departure: option.inherit_closed_to_departure,
          inherit_max_availability: option.inherit_max_availability,
          inherit_max_stay: option.inherit_max_stay,
          inherit_min_stay_arrival: option.inherit_min_stay_arrival,
          inherit_min_stay_through: option.inherit_min_stay_through,
          inherit_min_stay: option.inherit_min_stay,
          inherit_rate: option.inherit_rate,
          inherit_stop_sell: option.inherit_stop_sell,
        });
      });
    records.push(ratePlan);
  });

  options.forEach((option) => records.push(option));

  return records;
};

const inventoryNow = () => {
  return nowWithOffset(...INVENTORY_TIME_OFFSET);
};

const dateFromURL = (URLParams) => {
  if (!URLParams.date) {
    return inventoryNow();
  }

  const urlDate = safeParseDate(URLParams.date);

  if (!urlDate) {
    return inventoryNow();
  }

  // if date is in the past relative to the inventory time
  if (isBefore(urlDate, inventoryNow())) {
    return inventoryNow();
  }

  return urlDate;
};

class InventoryPage extends Component {
  static propTypes = {
    t: PropTypes.func,
    roomTypes: PropTypes.array,
    ratePlans: PropTypes.array,
    ratePlanNames: PropTypes.object,
    activePropertyId: PropTypes.string,
    channels: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const URLParams = getParamsFromURL();

    this.state = {
      initialized: false,
      daysCount: DEFAULT_DAYS_COUNT,
      selectedRestrictions: URLParams.selectedRestrictions || ["rate", "availability"],
      selectedRooms: URLParams.selectedRooms || [],
      selectedRates: URLParams.selectedRates || [],
      ari: {},
      availability: {},
      changes: {},
      overrideModal: this.getDefaultOverrideModel(),
      saveChangesInProgress: false,
      isBulkUpdateVisible: false,
      roomTypes: [],
      roomTypesById: {},
      activePropertyId: null,
      isErrorModalShown: false,
      errors: {},
      isLoadingRoomTypes: false,
      minStayType: null,
      isShowLogEnabled: false,
    };
  }

  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.activeProperty !== prevProps.activeProperty
      || this.props.propertiesOptions !== prevProps.propertiesOptions
    ) {
      this.initData();
    }
  }

  componentWillUnmount() {
    if (this.ariUpdateSubscription && this.ariUpdateSubscription.remove) {
      this.ariUpdateSubscription.remove();
      this.ariUpdateHandler.cancel();
    }

    EventEmitter.unbind("room_type:created", this.loadRoomTypes);
    EventEmitter.unbind("room_type:updated", this.loadRoomTypes);
    EventEmitter.unbind("rate_plan:created", this.loadRatePlans);
  }

  subscribeToUpdates() {
    EventEmitter.bind("room_type:created", this.loadRoomTypes);
    EventEmitter.bind("room_type:updated", this.loadRoomTypes);
    EventEmitter.bind("rate_plan:created", this.loadRatePlans);

    this.ariUpdateSubscription = subscribe("property:state_changed", this.ariUpdateHandler);
  }

  ariUpdateHandler = _.debounce((payload) => {
    const { activePropertyId } = this.state;

    if (activePropertyId === payload.id) {
      this.loadRestrictions();
    }
  }, DEBOUNCE_TIME);

  getDefaultOverrideModel = () => {
    return {
      isVisible: false,
      isValid: false,
      roomOrRateId: null,
      restriction: null,
      date: null,
      selectedDate: null,
      value: [],
      error: null,
      roomType: {},
      ratePlan: {},
    };
  };

  generateDates = (firstDate, daysCount) => {
    return daysBetween(firstDate, addDays(firstDate, daysCount - 1));
  };

  getActivePropertyId = () => {
    const { activeProperty, propertiesOptions } = this.props;

    if (activeProperty) {
      return activeProperty;
    }

    if (!propertiesOptions) {
      return null;
    }

    if (propertiesOptions.length === 1) {
      const [defaultProperty] = propertiesOptions;

      return defaultProperty.id;
    }

    return null;
  };

  initData = () => {
    const { isMobile } = this.props;

    const activePropertyId = this.getActivePropertyId();
    const isPropertiesOptions = this.props.propertiesOptions;

    this.setState({
      daysCount: isMobile ? MOBILE_DAYS_COUNT : DEFAULT_DAYS_COUNT,
    });

    if (!activePropertyId || !isPropertiesOptions) {
      this.setState({ activePropertyId });
      return;
    }

    this.setState({ activePropertyId }, () => {
      this.loadRoomTypes();
      this.loadRatePlans();
      this.loadProperty();
      this.loadChannels();

      if (this.ariUpdateSubscription) {
        this.ariUpdateHandler.cancel();

        return;
      }

      this.subscribeToUpdates();
    });
  };

  loadRoomTypes = () => {
    const { activePropertyId } = this.state;
    this.setState({ isLoadingRoomTypes: true });

    RoomTypes.options({ property_id: activePropertyId }).then((result) => {
      const roomTypes = getSortedArray(result || [], "position");
      const roomTypesById = result.reduce(
        (acc, { id, ...rest }) => ({ ...acc, [id]: { id, ...rest } }),
        {},
      );

      this.setState({ roomTypes, roomTypesById, isLoadingRoomTypes: false });
    });
  };

  loadRatePlans = () => {
    const { activePropertyId } = this.state;

    RatePlans.list({ property_id: activePropertyId });
  };

  loadProperty = () => {
    const { activePropertyId } = this.state;
    const { applyTz } = this.props;

    Properties.find(activePropertyId).then(({ min_stay_type, timezone }) => {
      const URLParams = getParamsFromURL();
      let currentDate = dateFromURL(URLParams);
      let defaultCurrentDate = inventoryNow();

      if (timezone) {
        currentDate = applyTz(currentDate, timezone);
        defaultCurrentDate = applyTz(defaultCurrentDate, timezone);
      }

      currentDate = startOfDay(currentDate);
      defaultCurrentDate = startOfDay(defaultCurrentDate);

      this.setState({
        initialized: true,
        currentDate,
        defaultCurrentDate,
        dates: this.generateDates(currentDate, DEFAULT_DAYS_COUNT),
        minStayType: min_stay_type,
      });

      this.onChangeDate(currentDate);
    });
  };

  loadChannels = () => {
    const { activePropertyId } = this.state;

    Channels.list({ property_id: activePropertyId }, { limit: 100 });
  };

  onFilterChange = (field) => {
    return (value) => {
      const { selectedRestrictions, selectedRooms, selectedRates, currentDate } = this.state;

      this.setState({ [field]: value });
      this.updateURL({
        ...{
          selectedRestrictions,
          selectedRooms,
          selectedRates,
          currentDate,
        },
        ...{
          [field]: value,
        },
      });
    };
  };

  updateURL(filters) {
    window.history.replaceState(
      null,
      null,
      `${window.location.origin}${window.location.pathname}${this.generateFilterSearchString(
        filters,
      )}`,
    );
  }

  generateFilterSearchString(filters) {
    const { defaultCurrentDate } = this.state;
    const { selectedRestrictions, selectedRooms, selectedRates, currentDate } = filters;
    const output = [];

    if (selectedRestrictions.sort().join("_") !== "availability_rate") {
      output.push(`rs=${selectedRestrictions.join(",")}`);
    }

    if (selectedRooms.length > 0) {
      output.push(`rt=${selectedRooms.join(",")}`);
    }

    if (selectedRates.length > 0) {
      output.push(`rp=${selectedRates.join(",")}`);
    }

    if (diffDays(defaultCurrentDate, currentDate) !== 0) {
      output.push(`date=${formatDate(currentDate)}`);
    }

    if (output.length > 0) {
      return `?${output.join("&")}`;
    }

    return "";
  }

  getNewCurrentDate(distance) {
    const { currentDate, defaultCurrentDate } = this.state;
    const newCurrentDate = addDays(currentDate, distance);

    if (isBefore(newCurrentDate, defaultCurrentDate)) {
      return defaultCurrentDate;
    }

    return newCurrentDate;
  }

  onScrollDates = (direction) => {
    return (event) => {
      event.preventDefault();

      const { daysCount } = this.state;
      const newCurrentDate = this.getNewCurrentDate(daysCount * direction);

      this.onChangeDate(newCurrentDate);
    };
  };

  onChangeDate = (newCurrentDate) => {
    const { selectedRestrictions, selectedRooms, selectedRates, daysCount } = this.state;
    this.setState({
      currentDate: newCurrentDate,
      ari: {},
      dates: this.generateDates(newCurrentDate, daysCount),
    });

    this.updateURL({
      selectedRestrictions,
      selectedRooms,
      selectedRates,
      currentDate: newCurrentDate,
    });
    this.loadRestrictions(newCurrentDate);
  };

  onFilterReset = () => {
    const { currentDate } = this.state;

    this.setState({
      selectedRates: [],
      selectedRooms: [],
      selectedRestrictions: ["availability", "rate"],
    });

    this.updateURL({
      selectedRestrictions: ["availability", "rate"],
      selectedRooms: [],
      selectedRates: [],
      currentDate,
    });
  };

  getOverrideValue = (roomOrRateId, restriction, dates, value) => {
    const { changes } = this.state;

    const days = getArrayDays(dates[0], dates[dates.length - 1]);

    const changesById = changes[roomOrRateId];

    if (changesById && Object.keys(changesById).length > 0) {
      return days.map((date, index) => {
        const valueByDate = changesById[date];
        const impactDates = valueByDate && Object.keys(valueByDate);

        if (impactDates && impactDates.some((i) => i === restriction)) {
          if (
            restriction === "closed_to_arrival"
            || restriction === "closed_to_departure"
            || restriction === "stop_sell"
            || restriction === "max_availability"
          ) {
            return valueByDate[restriction];
          }

          if (Number(valueByDate[restriction]) === value) {
            return Number(value[index]);
          }
          return Number(valueByDate[restriction]);
        }

        return value[index];
      });
    }
    return value;
  };

  onValueClick = (roomOrRateId, restriction, date, value) => {
    if (value.length === 0) {
      return () => {
        return null;
      };
    }

    const newValue = this.getOverrideValue(roomOrRateId, restriction, date, value);

    const ratePlansById = this.getRatePlansById();
    const { roomTypesById } = this.state;
    let ratePlan = {};
    let roomType = {};

    if (restriction === "availability") {
      roomType = roomTypesById[roomOrRateId];
    } else {
      ratePlan = ratePlansById[roomOrRateId];
      const { room_type_id } = ratePlan;

      roomType = roomTypesById[room_type_id];
    }

    return () => {
      this.setState({
        overrideModal: {
          isVisible: true,
          roomOrRateId,
          restriction,
          date: Array.isArray(date)
            ? [dayjs(date[0]), dayjs(date[1])]
            : [dayjs(date), dayjs(date)],
          selectedDate: date,
          isValid: true,
          value: newValue,
          ratePlan,
          roomType,
        },
      });
    };
  };

  onResetChanges = () => {
    this.setState({
      changes: {},
    });
  };

  onSaveChanges = () => {
    const { changes, ari, availability, roomTypesById } = this.state;
    const ratePlansById = this.getRatePlansById();
    const restrictionModels = [];
    const availabilityModels = [];
    // `changes` object can contain as keys ratePlanId or roomTypeId, this depends on restriction
    // for "availability" - roomTypeId, for other restrictions - ratePlanId
    Object.keys(changes).forEach((restrictableId) => {
      Object.keys(changes[restrictableId]).forEach((date) => {
        Object.keys(changes[restrictableId][date]).forEach((restriction) => {
          if (
            restriction !== "availability"
            && ari
            && ari[restrictableId]
            && ari[restrictableId][date]
          ) {
            ari[restrictableId][date][restriction] = changes[restrictableId][date][restriction];
          }
          if (restriction === "availability" && availability && availability[restrictableId]) {
            availability[restrictableId][date] = changes[restrictableId][date][restriction];
          }

          // for "availability" restriction we store in `changes` not restrictableId but roomTypeId
          // availability restriction is applied on date and room type

          if (restriction !== "availability") {
            const normalizedRestriction = restriction === "stop_sell_manual" ? "stop_sell" : restriction;
            restrictionModels.push({
              property_id: ratePlansById[restrictableId].property_id,
              rate_plan_id: restrictableId,
              date,
              [normalizedRestriction]: changes[restrictableId][date][restriction],
            });
          } else {
            availabilityModels.push({
              property_id: roomTypesById[restrictableId].property_id,
              room_type_id: restrictableId,
              date,
              availability: parseInt(changes[restrictableId][date][restriction]),
            });
          }
        });
      });
    });

    this.setState({
      saveChangesInProgress: true,
    });

    Promise.all([ARI.update(restrictionModels), ARI.updateAvailability(availabilityModels)]).then(
      (response) => {
        const [restrictionUpdateInfo, availabilityUpdateInfo] = response;
        const { warnings: restrictionErrors } = restrictionUpdateInfo.meta;
        const { warnings: availabilityErrors } = availabilityUpdateInfo.meta;

        if (restrictionErrors || availabilityErrors) {
          this.setState(
            {
              changes: {},
              saveChangesInProgress: false,
              isErrorModalShown: true,
              errors: {
                restrictionErrors,
                availabilityErrors,
              },
            },
            () => {
              this.initData(this.getActivePropertyId());
            },
          );

          return;
        }

        this.setState({
          changes: {},
          ari,
          availability,
          saveChangesInProgress: false,
        });
      },
    );
  };

  prepareValue(value, originalValue, restriction) {
    const handlers = {
      number: (val) => (isNaN(Number(val)) ? originalValue : Number(val)),
      money: (val) => (isNaN(Number(val)) ? originalValue : val),
      boolean: (val) => (typeof val === "boolean" ? val : originalValue),
      numberOrNull: (val) => {
        if (val === null) {
          return null;
        }
        return isNaN(Number(val)) ? originalValue : Number(val);
      },
    };

    const normalizers = {
      rate: handlers.money,
      booked: handlers.number,
      max_stay: handlers.number,
      availability: handlers.number,
      min_stay_arrival: handlers.number,
      min_stay_through: handlers.number,
      min_stay: handlers.number,
      availability_offset: handlers.number,
      stop_sell: handlers.boolean,
      stop_sell_manual: handlers.boolean,
      closed_to_arrival: handlers.boolean,
      closed_to_departure: handlers.boolean,
      max_availability: handlers.numberOrNull,
    };

    return normalizers[restriction](value);
  }

  removeMatchingChange = (changes, path) => {
    const [key, ...restPath] = path;

    const { [key]: changeToUpdate, ...restChanges } = changes;

    if (!restPath.length) {
      return restChanges;
    }

    const updatedChanges = this.removeMatchingChange(changeToUpdate, restPath);

    if (!Object.keys(updatedChanges).length) {
      return restChanges;
    }

    return { [key]: updatedChanges, ...restChanges };
  };

  handleSaveOverrideModal = () => {
    const { overrideModal, ari, availability, changes } = this.state;
    const { roomOrRateId, date, restriction, value } = overrideModal;

    let updatedChanges = _.cloneDeep(changes);
    const diff = diffDays(date[0], date[1]);
    const firstDate = formatDate(date[0]);

    for (let x = 0; x < diff + 1; x++) {
      const elDate = formatDate(addDays(firstDate, x));
      let originalValue;
      if (
        restriction !== "availability"
        && ari
        && ari[roomOrRateId]
        && ari[roomOrRateId][elDate]
        && typeof ari[roomOrRateId][elDate][restriction] !== "undefined"
      ) {
        originalValue = ari[roomOrRateId][elDate][restriction];
      }
      if (
        restriction === "availability"
        && availability
        && availability[roomOrRateId]
        && availability[roomOrRateId][elDate]
      ) {
        originalValue = availability[roomOrRateId][elDate];
      }

      const preparedValue = this.prepareValue(value[x], originalValue, restriction);

      if (typeof preparedValue !== "undefined") {
        const changePath = `${roomOrRateId}.${elDate}.${restriction}`;
        const oldChangeValue = _.get(updatedChanges, changePath);

        if (typeof oldChangeValue === "undefined") {
          if (preparedValue !== originalValue) {
            _.set(updatedChanges, changePath, preparedValue);
          }
        } else if (preparedValue !== originalValue) {
          _.set(updatedChanges, changePath, preparedValue);
        } else {
          updatedChanges = this.removeMatchingChange(updatedChanges, [
            roomOrRateId,
            elDate,
            restriction,
          ]);
        }
      }
    }

    this.setState({ changes: updatedChanges });
    this.handleCloseOverrideModal();
  };

  handleCloseOverrideModal = () => {
    setTimeout(() => {
      this.setState({
        overrideModal: this.getDefaultOverrideModel(),
      });
    }, ANIMATION_DURATION_IN_MS);
  };

  validateValueOverride(valueOverride) {
    return validateRestriction(valueOverride.restriction, valueOverride.value);
  }

  handleModalInputChange = (field) => {
    return (value) => {
      const { ari, overrideModal } = this.state;
      const { roomOrRateId, selectedDate } = overrideModal;
      const changes = overrideModal;

      if (value && value.target && value.target.type === "number") {
        changes[field] = value.target.value;
      } else {
        changes[field] = value;
      }

      if (field === "restriction") {
        if (
          ari[roomOrRateId]
          && ari[roomOrRateId][selectedDate]
          && ari[roomOrRateId][selectedDate][value]
        ) {
          changes.value = ari[roomOrRateId][selectedDate][value];
        } else {
          changes.value = booleanRestrictions.indexOf(value) === -1 ? 0 : false;
        }
      }

      const [isValid, error] = this.validateValueOverride({ ...overrideModal, ...changes });
      changes.isValid = isValid;
      changes.error = error;

      this.setState({
        overrideModal: changes,
      });
    };
  };

  onOpenBulkUpdate = () => {
    this.setState({
      isBulkUpdateVisible: true,
    });
  };

  onCloseBulkUpdate = () => {
    this.setState({
      isBulkUpdateVisible: false,
    });
  };

  onSettingClick = () => {
    const { navigate, routes } = this.props;

    navigate(pathBuilder(routes.userAppRoutes.inventory.settings));
  };

  handleOpenAvailabilityRules = () => {
    const { navigate, routes } = this.props;

    navigate(pathBuilder(routes.userAppRoutes.inventory.availabilityRules));
  };

  onSubmitBulkUpdate = (models, callback) => {
    const { ari } = this.state;

    models.forEach((model) => {
      if (ari && ari[model.rate_plan_id] && ari[model.rate_plan_id][model.date]) {
        ari[model.rate_plan_id][model.date] = { ...ari[model.rate_plan_id][model.date], ...model };
      }
    });

    ARI.update(models).then(() => {
      this.setState({
        ari,
      });
      if (typeof callback === "function") {
        callback();
      }
      this.loadRestrictions();
    });
  };

  onToggleShowLog = () => {
    const { t } = this.props;
    if (!this.state.isShowLogEnabled) {
      notification.open({
        message: t("inventory_page:changes_log:notification:title"),
        description: t("inventory_page:changes_log:notification:body"),
      });
    }
    this.setState(({ isShowLogEnabled }) => ({
      isShowLogEnabled: !isShowLogEnabled,
    }));
  };

  getResponseDates = (data) => {
    if (data) {
      const isData = Object.values(data).length > 0;
      const lastDateIndex = isData && Object.values(Object.values(data)[0]).length;
      const dateFrom = isData && getObjectKeyByOrderNumber(Object.values(data)[0], 1);
      const dateTo = isData && getObjectKeyByOrderNumber(Object.values(data)[0], lastDateIndex);

      return {
        dateFrom,
        dateTo,
      };
    }

    return null;
  };

  loadRestrictions = _.debounce((requiredDate) => {
    const { currentDate, daysCount, activePropertyId } = this.state;

    requiredDate = requiredDate || currentDate;

    const params = {
      date: {
        gte: formatDate(requiredDate),
        lte: formatDate(addDays(requiredDate, daysCount - 1)),
      },
      restrictions: VISIBLE_RESTRICTIONS,
    };

    const paramsDateFrom = params.date.gte;

    if (activePropertyId) {
      params.property_id = activePropertyId;
    }

    ARI.availability(params).then((response) => {
      const { dateFrom } = this.getResponseDates(response.data);
      const isActualResponse = isBetween(dateFrom, addDays(paramsDateFrom, -2), addDays(paramsDateFrom, 2));

      if (isActualResponse) {
        this.setState({
          availability: response.data,
        });
      }
    });

    ARI.get(params).then((response) => {
      const { dateFrom } = this.getResponseDates(response.data);
      const isActualResponse = isBetween(dateFrom, addDays(paramsDateFrom, -2), addDays(paramsDateFrom, 2));

      if (isActualResponse) {
        this.setState({
          ari: response.data,
        });
      }
    });
  }, DEBOUNCE_LOAD_RESTRICTIONS_TIME);

  handleErrorModalToggle = () => {
    this.setState(({ isErrorModalShown, errors }) => ({
      isErrorModalShown: !isErrorModalShown,
      errors: isErrorModalShown ? {} : errors,
    }));
  };

  fillOccupancy = (entities) => {
    return entities.map((ratePlan) => {
      const primaryOption = ratePlan.options.filter((el) => el.is_primary)[0];
      return Object.assign(ratePlan, { occupancy: primaryOption.occupancy });
    });
  };

  getRatePlansById = () => {
    const { ratePlans } = this.props;
    const ratePlansValues = ratePlans && Object.values(ratePlans);
    if (ratePlans) {
      return extractRateOptions(ratePlansValues).reduce((acc, el) => {
        acc[el.id] = el;
        return acc;
      }, {});
    }
    return {};
  };

  render() {
    const {
      t,
      routes,
      ratePlanNames,
      propertiesOptions,
      channels,
      appMode,
      isMobile,
      availabilityRuleOptions,
      isAvailabilityRuleOptionsLoading,
    } = this.props;
    const ratePlans = this.fillOccupancy(extractRateOptions(this.props.ratePlans || []));
    const ratePlansById = this.getRatePlansById();
    const {
      currentDate,
      daysCount,
      ari,
      availability,
      dates,
      selectedRates,
      selectedRooms,
      selectedRestrictions,
      changes,
      saveChangesInProgress,
      isBulkUpdateVisible,
      overrideModal,
      roomTypes,
      activePropertyId,
      roomTypesById,
      errors,
      isErrorModalShown,
      isLoadingRoomTypes,
      defaultCurrentDate,
      minStayType,
      initialized,
      isShowLogEnabled,
    } = this.state;

    const isRatePlansLoaded = this.props.ratePlans !== null;

    const hasChanges = Object.keys(changes).length > 0;
    const filters = {
      selectedRates,
      selectedRooms,
      selectedRestrictions,
    };

    let result = <Loading />;

    if (initialized && activePropertyId && isRatePlansLoaded && roomTypes && !isAvailabilityRuleOptionsLoading) {
      result = (
        <>
          {isMobile ? (
            <MobileInventory
              t={t}
              ratePlans={ratePlans}
              roomTypes={roomTypes}
              roomTypesById={roomTypesById}
              ratePlanNames={ratePlanNames}
              dates={dates}
              currentDate={currentDate}
              ari={ari}
              availability={availability}
              hasChanges={hasChanges}
              changes={changes}
              filters={filters}
              saveChangesInProgress={saveChangesInProgress}
              onOpenBulkUpdate={this.onOpenBulkUpdate}
              onValueClick={this.onValueClick}
              onChange={this.onFilterChange}
              onChangeDate={this.onChangeDate}
              onScrollDates={this.onScrollDates}
              onSaveChanges={this.onSaveChanges}
              isLoadingRoomTypes={isLoadingRoomTypes}
              availabilityRules={availabilityRuleOptions}
            />
          ) : (
            <>
              <InventoryFilters
                onChange={this.onFilterChange}
                onFilterReset={this.onFilterReset}
                onResetChanges={this.onResetChanges}
                onSaveChanges={this.onSaveChanges}
                hasChanges={hasChanges}
                onOpenBulkUpdate={this.onOpenBulkUpdate}
                onToggleShowLog={this.onToggleShowLog}
                onSettingClick={this.onSettingClick}
                onOpenAvailabilityRules={this.handleOpenAvailabilityRules}
                t={t}
                filters={filters}
                ratePlans={ratePlans}
                roomTypes={roomTypes}
                ratePlanNames={ratePlanNames}
                saveChangesInProgress={saveChangesInProgress}
                minStayType={minStayType}
                isShowLogEnabled={isShowLogEnabled}
                appMode={appMode}
              />
              <InventoryTable
                t={t}
                ari={ari}
                availability={availability}
                changes={changes}
                ratePlans={ratePlans}
                channels={channels || {}}
                roomTypes={roomTypes}
                roomTypesById={roomTypesById}
                dates={dates}
                onScrollDates={this.onScrollDates}
                onValueClick={this.onValueClick}
                onChangeDate={this.onChangeDate}
                daysCount={daysCount}
                currentDate={currentDate}
                filters={filters}
                ratePlanNames={ratePlanNames}
                isLoadingRoomTypes={isLoadingRoomTypes}
                defaultCurrentDate={defaultCurrentDate}
                minStayType={minStayType}
                isShowLogEnabled={isShowLogEnabled}
                onLogShown={this.onToggleShowLog}
                availabilityRules={availabilityRuleOptions}
              />
            </>
          )}
          <InventoryBulkUpdate
            isMobile={isMobile}
            roomTypes={roomTypes}
            visible={isBulkUpdateVisible}
            onClose={this.onCloseBulkUpdate}
            onSubmitBulkUpdate={this.onSubmitBulkUpdate}
            defaultCurrentDate={defaultCurrentDate}
            minStayType={minStayType}
          />
          <InventoryErrorModal
            errors={errors}
            visible={isErrorModalShown}
            ratePlansById={ratePlansById}
            onClose={this.handleErrorModalToggle}
          />

          {overrideModal.isVisible && (
            <InventoryValueOverrideModal
              t={t}
              {...overrideModal}
              isMobile={isMobile}
              ari={ari}
              availability={availability}
              defaultCurrentDate={defaultCurrentDate}
              changes={changes[overrideModal.roomOrRateId]}
              handleOk={this.handleSaveOverrideModal}
              handleCancel={this.handleCloseOverrideModal}
              handleModalInputChange={this.handleModalInputChange}
              minStayType={minStayType}
            />
          )}

          <Outlet context={{ closePath: pathBuilder(routes.userAppRoutes.inventory) }} />
        </>
      );
    }

    if (!activePropertyId && propertiesOptions && propertiesOptions.length > 1) {
      result = <Page.ErrorMessage text={t("inventory_page:error_message")} icon={ErrorImage} />;
    }

    return (
      <Page.Main>
        {result}
      </Page.Main>
    );
  }
}

const countUnique = (iterable) => {
  return new Set(iterable).size;
};

const excludeChannelRates = (ratePlans) => {
  if (ratePlans === null) {
    return ratePlans;
  }
  const channelRates = ratePlans.filter((ratePlan) => ratePlan.channel_id);

  return ratePlans.map((ratePlan) => {
    ratePlan.count_of_channels = countUnique(
      channelRates
        .filter((el) => el.parent_rate_plan_id === ratePlan.id)
        .map((el) => el.channel_id),
    );

    return ratePlan;
  });
};

const mapStateToProps = ({ ratePlans, session, properties, channels }) => {
  const propertiesOptions = properties.options;

  return {
    appMode: session.appMode,
    ratePlans: excludeChannelRates(getSortedArray(extractPerPersonRates(ratePlans), "title")),
    ratePlanNames: titledIds(ratePlans),
    activeProperty: session.activeProperty,
    propertiesOptions,
    session,
    channels,
  };
};

export default withMediaQuery({
  isMobile: { maxWidth: MOBILE_MAX_WIDTH },
})(
  withTranslation()(
    withRouter(
      connect(mapStateToProps)(
        withTimezones(
          withAvailabilityRuleOptions(
            InventoryPage,
          ),
        ),
      ),
    ),
  ),
);
