import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import classnames from "classnames";

import Avatar from "components/users/avatar/avatar";

import { pathBuilder } from "routing";
import useRouting from "routing/use_routing";

import { bookingDate, toLocalFromNow } from "utils/dates";

import MessageStatus from "./message_status";
import messagesDirections from "./messages_directions";

import styles from "./message.module.css";

const LEFT_DIRECTION = "left";
const RIGHT_DIRECTION = "right";
const MODAL_WIDTH = "fit-content";

function MessagesItem({ item, style, handleResendMessage, sender, receiver }) {
  const { t } = useTranslation();
  const [imgUrl, setImgUrl] = useState(null);
  const { userAppRoutes } = useRouting();

  const { attributes, requestId } = item;

  const { message, insertedAt, sender: senderType, status, attachments, attachmentId } = attributes;
  const insertedAtValue = toLocalFromNow(insertedAt);

  const onCloseModal = () => setImgUrl(null);
  const threadId = item?.relationships?.messageThread?.data?.id;

  const systemMessageContent = useCallback((event, meta) => {
    let result;
    switch (event) {
      case "reservation_requested":
        result = (
          <span>
            Received reservation request{" "}
            <Link
              to={pathBuilder(userAppRoutes.messages.dialog.liveFeedEventView, {
                threadId,
                eventId: meta.liveFeedEventId,
              })}
            >
              {meta.otaReservationCode}
            </Link>
            .
          </span>
        );
        break;

      case "alteration_requested":
        result = (
          <span>
            Received alteration request{" "}
            <Link
              to={pathBuilder(userAppRoutes.messages.dialog.liveFeedEventView, {
                threadId,
                eventId: meta.liveFeedEventId,
              })}
            >
              {meta.otaReservationCode}
            </Link>
            .
          </span>
        );
        break;

      case "close_thread":
        result = <span>Thread was closed by {meta.userName}.</span>;
        break;

      case "open_thread":
        result = <span>Thread was opened by {meta.userName}.</span>;
        break;

      case "inquiry":
        result = (
          <span>
            New inquiry for {meta.bookingDetails.listingName}<br />
            Checkin Date: {bookingDate(meta.bookingDetails.checkinDate, t("formats:date_time_with_weekday"))},
            checkout date: {bookingDate(meta.bookingDetails.checkoutDate, t("formats:date_time_with_weekday"))}<br />
            Expected payout amount: {meta.bookingDetails.expectedPayoutAmountAccurate} {meta.bookingDetails.currency}
          </span>
        );
        break;

      default:
        result = null;
        break;
    }

    return result;
  }, [threadId, userAppRoutes, t]);

  const messageContent = useMemo(() => {
    if (senderType === "system") {
      return (
        <p className={styles.message}>
          <strong>{t(`messages_page:system_messages:${message}`)}</strong>
          <br />
          {systemMessageContent(message, attributes.meta)}
        </p>
      );
    }

    if (attachmentId && !attachments?.length) {
      return (
        <div className={styles.message}>
          <div className={`${styles.attachment} ${styles.attachment__loading}`}>
            <LoadingOutlined />
          </div>
        </div>
      );
    }

    if (attachments?.length > 0) {
      return (
        <div className={styles.message}>
          {attachments.map((img) => {
            const onOpenModal = () => setImgUrl(img);

            return (
              <div
                key={img}
                onClick={onOpenModal}
                style={{ backgroundImage: `url("${img}")` }}
                className={styles.attachment}
              />
            );
          })}
        </div>
      );
    }

    return <p className={styles.message}>{message}</p>;
  }, [attachmentId, attachments, message, attributes, senderType, systemMessageContent, t]);

  const avatarName = useMemo(() => {
    const direction = messagesDirections[senderType].postfix;
    if (direction === LEFT_DIRECTION) {
      return sender;
    }
    if (direction === RIGHT_DIRECTION) {
      return receiver;
    }
    return "";
  }, [sender, receiver, senderType]);

  const wrapperClassName = classnames(
    styles.messageWrapper,
    styles[`messageWrapper__${messagesDirections[senderType].postfix}`],
  );

  const onResendMessage = useCallback(() => {
    handleResendMessage({ message }, requestId);
  }, [message, requestId, handleResendMessage]);

  const wrapperStyle = { ...style, ...messagesDirections[senderType].position };

  return (
    <>
      <div className={wrapperClassName} style={wrapperStyle}>
        <p className={styles.messageInsertedAtValue}>{insertedAtValue}</p>
        <div className={styles.messageInner}>
          <div className={styles.messageAvatar}>
            <Avatar name={avatarName} />
          </div>
          {messageContent}
          <MessageStatus status={status} handleResendMessage={onResendMessage} />
        </div>
      </div>
      <Modal
        width={MODAL_WIDTH}
        className={styles.attachment_modal}
        open={!!imgUrl}
        destroyOnClose
        footer={null}
        onCancel={onCloseModal}
      >
        <img src={imgUrl} className={styles.attachment_modal_img} alt="attachment" />
      </Modal>
    </>
  );
}

MessagesItem.propTypes = {
  item: PropTypes.object,
  style: PropTypes.object,
  handleResendMessage: PropTypes.func,
  sender: PropTypes.string,
  receiver: PropTypes.string,
};

export default MessagesItem;
