import React from "react";
import { useTranslation } from "react-i18next";

import ChannexDrawer from "drawers/channex_drawer";

import ChannelEventsView from "components/channel_events_view";

export default function ChannelEventControlledDrawer({ visible, actionId, onClose, zIndex }) {
  const { t } = useTranslation();

  const title = t("channel_events_page:channel_events_view_dialog:header");

  return (
    <ChannexDrawer title={title} visible={visible} onClose={onClose} zIndex={zIndex}>
      {() => <ChannelEventsView actionId={actionId} />}
    </ChannexDrawer>
  );
}
