import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Tag } from "antd";

import getBookingRevisionErrors from "utils/get_booking_revision_errors";

const STATUS_COLORS = {
  new: "green",
  cancelled: "red",
  modified: "orange",
};

class BookingRevisionTags extends Component {
  renderErrorTags = () => {
    const { t, revision } = this.props;
    const errors = getBookingRevisionErrors(revision);

    const errorTags = errors.map((error, key) => (
      <Tag color="red" key={key}>
        {t(`bookings_page:booking_view_dialog:error:${error}`)}
      </Tag>
    ));

    return errorTags;
  };

  render() {
    const { t, revision } = this.props;
    const { status } = revision;

    return (
      <>
        <Tag color={STATUS_COLORS[status]}>
          {t(`bookings_page:booking_view_dialog:status:${status}`)}
        </Tag>
        {this.renderErrorTags()}
      </>
    );
  }
}

export default withTranslation()(BookingRevisionTags);
