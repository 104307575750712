import attributesExtractor from "../utils/attributes_extractor";
import extractRelationships from "../utils/relationships_extractor";

import Groups from "./groups";

let transport;
let storage;

const ENDPOINT = "properties";

export default class Properties {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list(filter = {}, pagination = {}, order = {}) {
    return transport.send("GET", ENDPOINT, { filter, pagination, order }).then((response) => {
      const entities = extractRelationships(response.data);

      storage.propertiesLoad(entities, response.meta);

      return {
        entities,
        meta: response.meta,
      };
    });
  }

  health(filter = {}, pagination = {}, order = {}) {
    return transport
      .send("GET", `${ENDPOINT}/health`, { filter, pagination, order })
      .then((response) => {
        storage.propertiesHealthLoad(response.data, response.meta);
        return response;
      });
  }

  find(id) {
    return transport.send("GET", `${ENDPOINT}/${id}`).then((response) => {
      return extractRelationships(response.data);
    });
  }

  options(filter = {}) {
    return transport.send("GET", `${ENDPOINT}/options`, { filter }).then(({ data }) => {
      return attributesExtractor(data);
    });
  }

  setOptions(options) {
    storage.propertiesOptionsLoad(options);
  }

  create(attrs) {
    return transport.send("POST", ENDPOINT, { property: attrs }).then((response) => {
      const property = extractRelationships(response.data);
      storage.propertiesOptionsAdd(property);

      if (property.groups) {
        Object.keys(property.groups).forEach((groupId) => {
          new Groups({ transport, storage }).find(groupId);
        });
      }

      return property;
    });
  }

  delete(id) {
    return transport.send("DELETE", `${ENDPOINT}/${id}`).then((response) => {
      return response;
    });
  }

  update(attrs) {
    return transport
      .send("PUT", `${ENDPOINT}/${attrs.id}`, { property: attrs })
      .then((response) => {
        const property = extractRelationships(response.data);

        return property;
      });
  }
}
