import RATE_ACTION_TYPES from "config/constants/rate_action_types";

const { SET } = RATE_ACTION_TYPES;

export const getMinValue = (val) => {
  const newValue = Object.values(val)
    .map(Number)
    .filter((item) => Number.isFinite(item));

  if (newValue.length === 0) {
    return null;
  }

  return Math.min(...newValue);
};

export const getPercent = (val, total) => {
  return (val / 100) * total;
};

export const validateArrayValues = (arr) => {
  const isPositive = arr.every((item) => Math.sign(item) !== -1);
  return isPositive;
};

export const roundValue = (val) => Math.round(val * 100) / 100;

export const getRangeValues = (arr, type) => {
  if (type === SET) {
    return `${roundValue(arr[0])}`;
  }
  if (arr.length > 1) {
    const min = Math.min.apply(null, arr);
    const max = Math.max.apply(null, arr);
    return `${roundValue(min)} - ${roundValue(max)}`;
  }

  return roundValue(Number(arr[0]));
};

export const resetArrayValue = (val) => val.map(() => 0);
