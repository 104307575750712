import countries from "i18n-iso-countries";
import deCountries from "i18n-iso-countries/langs/de.json";
import elCountries from "i18n-iso-countries/langs/el.json";
import enCountries from "i18n-iso-countries/langs/en.json";
import esCountries from "i18n-iso-countries/langs/es.json";
import huCountries from "i18n-iso-countries/langs/hu.json";
import itCountries from "i18n-iso-countries/langs/it.json";
import ptCountries from "i18n-iso-countries/langs/pt.json";
import ruCountries from "i18n-iso-countries/langs/ru.json";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import queryString from "query-string";

import SUPPORTED_LANGUAGES from "config/constants/supported_languages";

import AppStorageManager from "utils/app_storage_manager";

import dayjs from "./dayjs";

const DEFAULT_LANG = "en";

countries.registerLocale(enCountries);
countries.registerLocale(esCountries);
countries.registerLocale(ptCountries);
countries.registerLocale(ruCountries);
countries.registerLocale(deCountries);
countries.registerLocale(elCountries);
countries.registerLocale(itCountries);
countries.registerLocale(huCountries);

const isLanguageSupported = (lngCode) => {
  return SUPPORTED_LANGUAGES.find((language) => language.key === lngCode);
};

const query = queryString.parse(window.location.search);
const queryLang = query.lng?.toLowerCase();

AppStorageManager.removeItem("CHANNEX_LANGUAGE");
if (isLanguageSupported(queryLang)) {
  AppStorageManager.setItem("CHANNEX_LANGUAGE", queryLang);
}

const getDefaultLanguage = () => {
  const sessionLang = AppStorageManager.getItem("CHANNEX_LANGUAGE");
  if (isLanguageSupported(sessionLang)) {
    return sessionLang;
  }

  const user = AppStorageManager.getItem("CHANNEX_USER");
  const userLang = user?.preferred_language;
  if (isLanguageSupported(userLang)) {
    return userLang;
  }

  const browserLanguage = window.navigator.language?.substring(0, 2);
  if (isLanguageSupported(browserLanguage)) {
    return browserLanguage;
  }

  return DEFAULT_LANG;
};

const i18nInit = () => i18next
  .use(HttpApi)
  .init({
    interpolation: { escapeValue: false },
    defaultNS: "common",
    lng: getDefaultLanguage(),
    fallbackLng: DEFAULT_LANG,
    keySeparator: ":",
    backend: {
      loadPath: "https://cdn.lokex.io/translations/50ba2612-ef0a-4b7b-8c79-a1f4a6ce5fdc/{{lng}}.json",
    },
  });

i18next.on("languageChanged", (lng) => {
  dayjs.locale(lng);
});

export default i18nInit;
