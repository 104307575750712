import queryString from "query-string";
import { convertToCamelCase, convertToSnakeCase } from "store/utils/case_converter";

import attributesExtractor from "../utils/attributes_extractor";

let transport;
let storage;

const ENDPOINT = "applications";

export default class Applications {
  constructor(container) {
    transport = container.transport;
    storage = container.storage;
  }

  list() {
    return transport
      .send("GET", ENDPOINT)
      .then(convertToCamelCase)
      .then(({ data }) => attributesExtractor(data));
  }

  loadInstalled() {
    return transport
      .send("GET", `${ENDPOINT}/installed`)
      .then(convertToCamelCase)
      .then(({ data }) => {
        const convertedData = attributesExtractor(data);
        storage.applicationsLoadInstalled(convertedData);

        return convertedData;
      });
  }

  install(applicationId, propertyId) {
    const requestParams = convertToSnakeCase({
      applicationInstallation: {
        propertyId,
        applicationId,
      },
    });

    return transport
      .send("POST", `${ENDPOINT}/install`, requestParams)
      .then(convertToCamelCase)
      .then(({ data }) => {
        const convertedData = attributesExtractor(data);
        storage.applicationsAddInstalled(convertedData);

        return convertedData;
      });
  }

  uninstall(installationId) {
    return transport.send("DELETE", `${ENDPOINT}/${installationId}/uninstall`).then(() => {
      storage.applicationsRemoveInstalled(installationId);
    });
  }

  update(installation) {
    return transport
      .send("PUT", `${ENDPOINT}/installed/${installation.id}`, {
        application_installation: installation,
      })
      .then(convertToCamelCase)
      .then(({ data }) => {
        const convertedData = attributesExtractor(data);
        storage.applicationsAddInstalled(convertedData);

        return convertedData;
      });
  }

  health(installation) {
    return transport
      .send("GET", `${ENDPOINT}/installed/${installation.id}/health`)
      .then(convertToCamelCase)
      .then(({ data }) => {
        return data;
      });
  }

  async action({ appCode, action, query = {} }) {
    let url = `${ENDPOINT}/${appCode}/${action}`;
    const qs = queryString.stringify(convertToSnakeCase(query));

    if (qs) {
      url += `?${qs}`;
    }

    return transport
      .send("POST", url)
      .then(convertToCamelCase)
      .then(({ data }) => {
        const convertedData = attributesExtractor(data);

        return convertedData;
      }).catch((error) => {
        if (!error.isValidationError) {
          throw error;
        }

        return error.body;
      });
  }
}
