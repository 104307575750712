import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Card, Col, Empty, Row, Tag } from "antd";

import { formatToLocal } from "utils/dates";

import bookingViewStyles from "../booking_view.module.css";
import styles from "./revision_card.module.css";

const STATUS_COLORS = {
  new: "green",
  cancelled: "red",
  modified: "orange",
};

class RevisionCard extends Component {
  t = (word) => {
    const { t } = this.props;
    return t(`bookings_page:booking_view_dialog:${word}`);
  };

  renderRevisionHeader = (revision) => {
    const { t } = this.props;

    return (
      <Row>
        <Col span={18} className={styles.revisionTitle}>
          {this.t("system_id")}: {revision.system_id}
          <br />
          <small>{formatToLocal(revision.inserted_at, t("formats:date_time_with_seconds"))}</small>
        </Col>
        <Col span={6} className={bookingViewStyles.revision_title_type}>
          <Tag color={STATUS_COLORS[revision.status]}>{this.t(`status:${revision.status}`)}</Tag>
        </Col>
      </Row>
    );
  };

  renderAcks = (acks) => {
    const { t } = this.props;

    if (acks.length === 0) {
      return <Empty className={styles.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    return (
      <ul className={styles.ackList}>
        {acks.map((ack) => (
          <li key={ack.id}>
            {ack.user_name} ack at{" "}
            {formatToLocal(ack.inserted_at, t("formats:date_time_with_seconds"))}
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { revision } = this.props;

    return (
      <Card
        className={styles.revisionCard}
        size="small"
        title={this.renderRevisionHeader(revision)}
      >
        {this.renderAcks(revision.acks)}
      </Card>
    );
  }
}

export default withTranslation()(RevisionCard);
