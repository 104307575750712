export default [
  "all_events",
  "booking_new",
  "booking_modification",
  "booking_cancellation",
  "sync_error",
  "reservation_request",
  "alteration_request",
  "booking_unmapped_room",
  "booking_unmapped_rate",
  "message",
  "review",
  "general_error",
  "inquiry",
  "airbnb_notification",
  "rate_error",
];
