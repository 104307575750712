import React from "react";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined, SolutionOutlined } from "@ant-design/icons";

import LinkButton from "components/forms/buttons/link_button";

import styles from "./chat_header_actions.module.css";

export default function ChatHeaderActions({
  handleCloseThread,
  handleOpenThread,
  handleOpenBooking,
  isClosed,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {handleOpenBooking && (
        <LinkButton className={styles.action} onClick={handleOpenBooking}>
          <SolutionOutlined />
          <p>{t("messages_page:dialog:open_booking")}</p>
        </LinkButton>
      )}
      {!isClosed && (
        <LinkButton className={styles.action} onClick={handleCloseThread}>
          <CheckCircleOutlined />
          <p>{t("messages_page:dialog:close_conversation")}</p>
        </LinkButton>
      )}
      {isClosed && (
        <LinkButton className={styles.action} onClick={handleOpenThread}>
          <CheckCircleOutlined />
          <p>{t("messages_page:dialog:open_conversation")}</p>
        </LinkButton>
      )}
    </div>
  );
}
